import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Checkbox, CircularProgress, Box, Typography } from '@mui/material';

const SelectAnbiam = ({ open, onClose, onSave, anbiamList = [] , parishUser}) => {
  const [anbiams, setAnbiams] = useState([]);
  const [selectedAnbiams, setSelectedAnbiams] = useState(anbiamList.map(anbiam => anbiam._id)); // Preselect already selected Anbiams
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchAnbiams = async () => {
      setLoading(true);
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/anbiams');
        if (!response.ok) {
          throw new Error('Failed to fetch anbiams');
        }
        const data = await response.json();
        setAnbiams(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchAnbiams();
  }, []);

  const handleToggle = (anbiamId) => {
    setSelectedAnbiams((prevSelected) => {
      if (prevSelected.includes(anbiamId)) {
        return prevSelected.filter((id) => id !== anbiamId);
      } else {
        return [...prevSelected, anbiamId];
      }
    });
  };

  const handleSave = () => {
  const selectedAnbiamsData = anbiams.filter((anbiam) => selectedAnbiams.includes(anbiam._id)).map(anbiam => ({
    _id: anbiam._id,
    anbiamName: anbiam.anbiamName,
    representativeName: anbiam.representativeName,
    phoneNo: anbiam.phoneNo
  }));
  
  console.log("Selected Anbiams Data:", selectedAnbiamsData);
  onSave(selectedAnbiamsData);
  onClose();
};

  // Filter anbiams based on parishUser's parish name
  var filteredAnbiams = anbiams;

  // Ensure parishUser is defined and has a name before filtering
  if (parishUser && parishUser.name) {
    filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select Anbiam</DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </Box>
        ) : error ? (
          <Typography color="error" variant="body1" textAlign="center">
            {error}
          </Typography>
        ) : filteredAnbiams.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="anbiam table">
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Anbiam Name</TableCell>
                  <TableCell>Representative Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Families</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredAnbiams.map((anbiam) => (
                  <TableRow key={anbiam._id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedAnbiams.includes(anbiam._id)}
                        onChange={() => handleToggle(anbiam._id)}
                      />
                    </TableCell>
                    <TableCell>{anbiam.anbiamName}</TableCell>
                    <TableCell>{anbiam.representativeName}</TableCell>
                    <TableCell>{anbiam.phoneNo}</TableCell>
                    <TableCell>
                      <ul>
                        {(anbiam.families || []).map((family, i) => ( // Ensure families is always an array
                          <li key={i}>
                            <Typography variant="body2" color="text.secondary">
                              {family.name || 'No Family Listed'}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body1" color="text.secondary" textAlign="center">
            No Anbiams available.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" variant="contained" disabled={selectedAnbiams.length === 0}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SelectAnbiam;
