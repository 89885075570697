import React from 'react';
import { Typography } from '@mui/material';

export default function AdminSubscription() {
  // Example admin subscription data
  const subscriptions = [
    { user: 'John Doe', month: 'June', status: 'Paid' },
    { user: 'Jane Doe', month: 'June', status: 'Pending' },
    { user: 'John Doe', month: 'July', status: 'Pending' }
  ];

  return (
    <div>
      <Typography variant="h4">User Subscriptions</Typography>
      <ul>
        {subscriptions.map((subscription, index) => (
          <li key={index}>
            <Typography variant="body1">
              {subscription.user} - {subscription.month}: {subscription.status}
            </Typography>
          </li>
        ))}
      </ul>
    </div>
  );
}
