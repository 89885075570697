import React from 'react';
import {
  Container,
  TextField,
  Button,
  Grid,
  Typography,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
  FormHelperText,
} from '@mui/material';
import { useForm, Controller } from 'react-hook-form';

const AddDevotee = () => {
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle the form submission here, e.g., send to a server
  };

  return (
    <Container maxWidth="md">
      <Typography variant="h4" gutterBottom>
        Add Devotee
        </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          {/* Family Card Number */}
          <Grid item xs={12}>
            <Controller
              name="familyCardNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Family Card Number"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name */}
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Sex */}
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel>Sex</InputLabel>
              <Controller
                name="sex"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select {...field} label="Sex">
                    <MenuItem value="male">Male</MenuItem>
                    <MenuItem value="female">Female</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          {/* Photo */}
          <Grid item xs={12}>
            <Controller
              name="photo"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Photo
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* Phone Number */}
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Phone Number"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Birth Place and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="birthPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Birth Place"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="birthDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Birth Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Baptism Place and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="baptismPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Baptism Place"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="baptismDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Baptism Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name of the Church Father who gave Baptism */}
          <Grid item xs={12}>
            <Controller
              name="baptismFatherName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name of the Church Father (Baptism)"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Certificate of Baptism */}
          <Grid item xs={12}>
            <Controller
              name="baptismCertificate"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Baptism Certificate
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* First Holy Communion Place and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="communionPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="First Holy Communion Place"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="communionDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="First Holy Communion Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name of the Church Father who gave Communion */}
          <Grid item xs={12}>
            <Controller
              name="communionFatherName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name of the Church Father (Communion)"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Certificate of Communion */}
          <Grid item xs={12}>
            <Controller
              name="communionCertificate"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Communion Certificate
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* Confirmation Place and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="confirmationPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Confirmation Place"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="confirmationDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Confirmation Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name of the Church Father who gave Confirmation */}
          <Grid item xs={12}>
            <Controller
              name="confirmationFatherName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name of the Church Father (Confirmation)"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Certificate of Confirmation */}
          <Grid item xs={12}>
            <Controller
              name="confirmationCertificate"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Confirmation Certificate
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* Spouse's Name */}
          <Grid item xs={12}>
            <Controller
              name="spouseName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Spouse's Name"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Marriage Place and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="marriagePlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Marriage Place"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="marriageDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Marriage Date"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name of the Church Father who Registered the Marriage */}
          <Grid item xs={12}>
            <Controller
              name="marriageFatherName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name of the Church Father (Marriage)"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Certificate of Marriage */}
          <Grid item xs={12}>
            <Controller
              name="marriageCertificate"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Marriage Certificate
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* Place of Death and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="deathPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Place of Death"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="deathDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Date of Death"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Place of Burial and Date */}
          <Grid item xs={12} sm={6}>
            <Controller
              name="burialPlace"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Place of Burial"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Controller
              name="burialDate"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  type="date"
                  label="Date of Burial"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Name of the Church Father who Blessed the Burial */}
          <Grid item xs={12}>
            <Controller
              name="burialFatherName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  fullWidth
                  label="Name of the Church Father (Burial)"
                  {...field}
                />
              )}
            />
          </Grid>

          {/* Certificate of Death */}
          <Grid item xs={12}>
            <Controller
              name="deathCertificate"
              control={control}
              render={({ field }) => (
                <Button variant="contained" component="label" fullWidth>
                  Upload Death Certificate
                  <input type="file" hidden {...field} />
                </Button>
              )}
            />
          </Grid>

          {/* Submit Button */}
          <Grid item xs={12}>
            <Button variant="contained" color="primary" fullWidth type="submit">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default AddDevotee;
