import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography
} from '@mui/material';

const SelectSubstation = ({ open, onClose, onSelect }) => {
  const [substations, setSubstations] = useState([]);

  useEffect(() => {
    const fetchSubstations = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/substations');
        const data = await response.json();
        setSubstations(data);
      } catch (error) {
        console.error('Error fetching substations:', error);
      }
    };

    if (open) {
      fetchSubstations();
    }
  }, [open]);

  const handleSelect = (substation) => {
    onSelect(substation);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select a Substation</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="substation table">
              <TableHead>
                <TableRow>
                  <TableCell>Substation Name</TableCell>
                  <TableCell>Anbiam List</TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {substations.map((substation) => (
                  <TableRow key={substation._id}>
                    <TableCell>{substation.substationName}</TableCell>
                    <TableCell>
                      <ul>
                        {substation.anbiam.map((anbiam, i) => (
                          <li key={i}>
                            <Typography variant="body2" color="text.secondary">
                              {anbiam.name || 'No Anbiam'}
                            </Typography>
                          </li>
                        ))}
                      </ul>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleSelect(substation)}>
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectSubstation;
