import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Box, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const SubstationList = ({parishUser}) => {
  const [substations, setSubstations] = useState([]);

  useEffect(() => {
    const fetchSubstations = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/substations');
        const data = await response.json();
        setSubstations(data);
      } catch (error) {
        console.error('Error fetching substations:', error);
      }
    };

    fetchSubstations();
  }, []);

  var Filteredsubstations=substations
  if(parishUser){

    Filteredsubstations = substations.filter(substation => substation.parishId === parishUser._id)

  }
  
  if(parishUser)
  {
    var Filteredsubstations = substations.filter(substation => substation.parishId === parishUser._id)
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Substation List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="substation table">
            <TableHead>
              <TableRow>
                <TableCell>Substation Name</TableCell>
                <TableCell>Anbiam List</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Filteredsubstations.map((substation) => (
                <TableRow key={substation._id}>
                  <TableCell>
                    {/* Route based on substation _id */}
                    <RouterLink to={`/substation/${substation._id}`}>
                      {substation.substationName}
                    </RouterLink>
                  </TableCell>
                  <TableCell>
                    <ul>
                      {substation.anbiam.map((anbiam, i) => (
                        <li key={i}>
                          <Typography variant="body2" color="text.secondary">
                            {anbiam.name || 'No Anbiam'}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Button variant="contained" color="primary" sx={{ mt: 2 }}>
          Add Substation
        </Button>
      </Box>
    </Container>
  );
};

export default SubstationList;
