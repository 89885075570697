import React, { useState } from 'react';
import { TextField, Button, Typography, Box } from '@mui/material';
import axios from 'axios';
import SelectAnbiam from '../Selecter/SelectAnbiam'; // Import your SelectAnbiam dialog
import SelectParish from '../Selecter/SelectParish'; // Import your SelectParish dialog

function CreateSubstation({parishUser}) {
  const [substationName, setSubstationName] = useState('');
  const [parishId, setParishId] = useState(''); // Parish ID to send to backend
  const [parishName, setParishName] = useState(''); // Parish Name to display
  const [selectedAnbiams, setSelectedAnbiams] = useState([]); // Store selected anbiams
  const [isAnbiamDialogOpen, setAnbiamDialogOpen] = useState(false); // Control anbiam dialog visibility
  const [isParishDialogOpen, setParishDialogOpen] = useState(false); // Control parish dialog visibility

  const handleAnbiamDialogOpen = () => {
    setAnbiamDialogOpen(true);
  };

  const handleAnbiamDialogClose = () => {
    setAnbiamDialogOpen(false);
  };

  const handleAnbiamSave = (anbiamList) => {
    setSelectedAnbiams(anbiamList);
    setAnbiamDialogOpen(false);
  };

  const handleParishDialogOpen = () => {
    setParishDialogOpen(true);
  };

  const handleParishDialogClose = () => {
    setParishDialogOpen(false);
  };

  const handleParishSelect = (parish) => {
    setParishId(parish._id);
    setParishName(parish.name);
  };

  const handleSubmit = async () => {
    // Ensure selectedAnbiams contains all necessary data
    const formattedAnbiams = selectedAnbiams.map(anbiam => ({
      id: anbiam._id, 
      name: anbiam.anbiamName
    }));
    
    console.log("Submitting the following data:", {
      substationName,
      parishId,
      parishName,
      anbiam: formattedAnbiams
    });
  
    try {
      const response = await axios.post(process.env.REACT_APP_API_URL+'/substation', {
        substationName,
        parishId,
        parishName,
        anbiam: formattedAnbiams  // Send selected Anbiams with id and name
      });
      
      if (response.status === 201) {
        alert('Substation created successfully!');
        setSubstationName('');
        setParishId('');
        setParishName('');
        setSelectedAnbiams([]);
      }
    } catch (error) {
      console.error('Error creating substation:', error);
      alert('Failed to create substation.');
    }
  };
  

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>Create Substation</Typography>

      <TextField
        label="Name of Substation"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={substationName}
        onChange={(e) => setSubstationName(e.target.value)}
      />

      {/* Button to open the Parish selection dialog */}
      <Box sx={{ mb: 2 }}>
        <Typography variant="h6">Selected Parish</Typography>
        {parishName ? (
          <Typography variant="body1">
            {parishName}
          </Typography>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No Parish selected
          </Typography>
        )}
        <Button variant="outlined" onClick={handleParishDialogOpen}>
          Select Parish
        </Button>
      </Box>

      <Typography variant="h6">Selected Anbiams</Typography>
      <Box sx={{ mb: 2 }}>
        {selectedAnbiams.length > 0 ? (
          <ul>
            {selectedAnbiams.map((anbiam) => (
              <li key={anbiam._id}>
                {anbiam.anbiamName} - {anbiam.representativeName}
              </li>
            ))}
          </ul>
        ) : (
          <Typography variant="body2" color="text.secondary">
            No Anbiams selected
          </Typography>
        )}
      </Box>

      <Button
        variant="outlined"
        onClick={handleAnbiamDialogOpen}
        sx={{ mb: 2 }}
      >
        Select Anbiams
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
      >
        Create Substation
      </Button>

      {/* Dialog components */}
      <SelectAnbiam
        open={isAnbiamDialogOpen}
        onClose={handleAnbiamDialogClose}
        onSave={handleAnbiamSave}
        anbiamList={selectedAnbiams || []}  // Ensure anbiamList is always an array
        parishUser={parishUser}
      />

      <SelectParish
        open={isParishDialogOpen}
        onClose={handleParishDialogClose}
        onSelect={handleParishSelect}
      />
    </Box>
  );
}

export default CreateSubstation;
