import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Link, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

const FamilyList = ({parishUser}) => {
  const [families, setFamilies] = useState([]);
  
  const [anbiams, setAnbiams] = useState([]);

  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/anbiams');
        const data = await response.json();
        setAnbiams(data);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };

    fetchAnbiams();
  }, []);

  // Filter anbiams based on parishUser's parish name

  var filteredAnbiams= anbiams

  if(parishUser)
  {
    filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  }

  useEffect(() => {
    // Fetch families from backend
    axios.get('/api/families')
      .then(response => {
        setFamilies(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the families!", error);
      });

  }, []);
  
  // Filter families based on anbiam
  var filteredFamilies = families.filter(family => 
    filteredAnbiams.some(anbiam => anbiam._id === family.anbiamId)
  );

  if(!parishUser)
    {
      filteredFamilies = families
    }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Family Card List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="family table">
            <TableHead>
              <TableRow>
                <TableCell>Family Card Number</TableCell>
                <TableCell>Family Head Name</TableCell>
                <TableCell>Family Photo</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Google Map Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredFamilies.map((family) => (
                <TableRow key={family._id.$oid}>
                  <TableCell>
                    <RouterLink to={`/family/${family._id}`}>
                      {family.familyCardNumber}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{family.familyHeadName}</TableCell>
                  <TableCell>
                    <img src={family.familyPhoto} alt={`${family.familyHeadName} Family`} style={{ width: '100px', height: '100px' }} />
                  </TableCell>
                  <TableCell>{family.phoneNumber}</TableCell>
                  <TableCell>
                    <Link href={family.googleMapLink} target="_blank" rel="noopener">
                      View on Google Maps
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default FamilyList;
