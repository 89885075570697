import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Button } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import SelectParish from '../Selecter/SelectParish';
import SelectSubstation from '../Selecter/SelectSubstation'; // Import SelectSubstation component
import SelectFamilyDialog from '../Selecter/SelectFamilyDialog';

export default function AnbiamDetails() {
  const { anbiamId } = useParams();
  const navigate = useNavigate();
  const [anbiam, setAnbiam] = useState(null);
  const [loading, setLoading] = useState(true);

  const [editingRepresentative, setEditingRepresentative] = useState(false); // Track editing for representative
  const [editingAnbiamName, setEditingAnbiamName] = useState(false); // Track editing for anbiam name
  const [editingParish, setEditingParish] = useState(false); // Track editing for parish
  const [editingSubstation, setEditingSubstation] = useState(false); // Track editing for substation

  const [originalAnbiam, setOriginalAnbiam] = useState(null); // Store original data

  // State to control the parish selection dialog
  const [selectParishDialogOpen, setSelectParishDialogOpen] = useState(false);
  const [selectFamilyDialogOpen, setSelectFamilyDialogOpen] = useState(false);

  
  useEffect(() => {
    const fetchAnbiamDetails = async () => {
      try {
        const response = await axios.get(`/api/anbiams/${anbiamId}`);
        setAnbiam(response.data);
        setOriginalAnbiam(response.data); // Save original data for cancellation
        setLoading(false);
      } catch (error) {
        console.error('Error fetching anbiam details:', error);
        setLoading(false);
      }
    };

    fetchAnbiamDetails();
  }, [anbiamId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setAnbiam({ ...anbiam, [name]: value });
  };

  const [selectSubstationDialogOpen, setSelectSubstationDialogOpen] = useState(false);


  const handleRemoveFamily = async (familyId) => {
    try {
      await axios.put(`/api/anbiams/${anbiamId}/remove_family/${familyId}`);
      // Update the local Anbiam state after family removal
      setAnbiam({
        ...anbiam,
        families: anbiam.families.filter(family => family.id !== familyId),
      });
    } catch (error) {
      console.error('Error removing family:', error);
    }
  };
  
  const handleSelectFamily = (selectedFamily) => {
    // Combine the selected family with the existing families
    const updatedFamilies = [...anbiam.families, selectedFamily];
  
    // Update the Anbiam state
    setAnbiam({
      ...anbiam,
      families: updatedFamilies,
    });
  
    // Trigger backend update to save the newly added family
    handleUpdate('families');
    setSelectFamilyDialogOpen(false); // Close the dialog after saving the family
  };
  
  

  const handleUpdate = async (field) => {
    try {
      console.log('Updating field:', field);
      console.log('Updated data:', anbiam);
      
      // Create a new object for the families update
      if (field === 'families') {
        // Only update the 'families' field on the backend
        const familiesToSend = anbiam.families.map(family => ({
          id: family.id,
          familyCardNumber: family.familyCardNumber,
        }));
        
        await axios.put(`/api/anbiams/${anbiamId}/families`, { families: familiesToSend });
      } else {
        await axios.put(`/api/anbiams/${anbiamId}`, anbiam);
      }
  
      // Disable editing after successful update for other fields
      if (field === 'representative') setEditingRepresentative(false);
      else if (field === 'anbiamName') setEditingAnbiamName(false);
      else if (field === 'parish') setEditingParish(false);
      else if (field === 'substation') setEditingSubstation(false);
    } catch (error) {
      console.error('Error updating anbiam:', error);
    }
  };
  
  
  

  const handleCancel = (field) => {
    setAnbiam(originalAnbiam); // Revert back to the original data
    if (field === 'representative') setEditingRepresentative(false);
    else if (field === 'anbiamName') setEditingAnbiamName(false);
    else if (field === 'parish') setEditingParish(false);
    else if (field === 'substation') setEditingSubstation(false);
  };

  const handleSelectParish = (selectedParish) => {
    // Set the selected parish in the Anbiam data
    setAnbiam({
      ...anbiam,
      parishId: selectedParish._id,
      parishName: selectedParish.name,
    });
  
    // Trigger the backend update after parish is selected
    handleUpdate('parish');
  };

  const handleSelectSubstation = (selectedSubstation) => {
    setAnbiam({
      ...anbiam,
      substationId: selectedSubstation._id,
      substationName: selectedSubstation.substationName,
    });
    handleUpdate('substation'); // Update backend with selected substation
  };
  
  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!anbiam) {
    return <Typography>No Anbiam found.</Typography>;
  }

  const handleAddFamily = () => {
    setSelectFamilyDialogOpen(true); // Open the dialog to select families
  };
  
   // Update this to handle saving selected families
   const handleSaveFamilies = (selectedFamilies) => {
      // Extract the necessary fields (id and familyCardNumber) from the selected families
      const newFamilies = selectedFamilies.map(family => ({
        id: family._id, // Family ID
        familyCardNumber: family.familyCardNumber, // Family Card Number
      }));
    
      // Ensure no duplicate families are added to the Anbiam
      const nonDuplicateFamilies = newFamilies.filter(newFamily =>
        !anbiam.families.some(existingFamily => existingFamily.id === newFamily.id)
      );
    
      // If there are new families to add, proceed with the backend update
      if (nonDuplicateFamilies.length > 0) {
        // Update the Anbiam state with the newly selected families
        setAnbiam(prevState => ({
          ...prevState,
          families: [...prevState.families, ...nonDuplicateFamilies],
        }));
    
        // Trigger backend update to save the newly added families
        handleUpdateFamilies(nonDuplicateFamilies);
      }
    
      setSelectFamilyDialogOpen(false); // Close the dialog after saving the families
    };
    
    const handleUpdateFamilies = async (newFamilies) => {
      try {
        console.log('Updating families:', newFamilies);
        // Send only the new families to the backend
        await axios.put(`/api/anbiams/${anbiamId}/families`, { families: newFamilies });
        console.log('Families updated successfully');
      } catch (error) {
        console.error('Error updating families:', error);
      }
    };
    
  

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!anbiam) {
    return <Typography>No Anbiam found.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <Typography variant="h4" gutterBottom>
            Anbiam Details
          </Typography>
          <Button variant="contained" color="primary" onClick={() => navigate(-1)}>
            Back
          </Button>
        </Box>

        {/* Edit Anbiam Name */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Anbiam Name"
            name="anbiamName"
            value={anbiam.anbiamName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingAnbiamName}
          />
          {editingAnbiamName ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleUpdate('anbiamName')}>
                Update
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => handleCancel('anbiamName')}>
                Cancel
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setEditingAnbiamName(true)}>
              Edit Anbiam Name
            </Button>
          )}
        </Box>

        {/* Edit Representative Name and Phone */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4 }}>
          <TextField
            label="Representative Name"
            name="representativeName"
            value={anbiam.representativeName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingRepresentative}
          />

          <TextField
            label="Phone Number"
            name="phoneNo"
            value={anbiam.phoneNo}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingRepresentative}
          />

          {editingRepresentative ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleUpdate('representative')}>
                Update
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => handleCancel('representative')}>
                Cancel
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setEditingRepresentative(true)}>
              Edit Representative Details
            </Button>
          )}
        </Box>

        

        {/* Parish Section */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4 }}>
          <TextField
            label="Parish ID"
            name="parishId"
            value={anbiam.parishId || ''}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingParish}
          />
          <TextField
            label="Parish Name"
            name="parishName"
            value={anbiam.parishName || ''}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingParish}
          />

          {editingParish ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleUpdate('parish')}>
                Update
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setSelectParishDialogOpen(true)}>
              Change Parish
            </Button>
          )}
        </Box>

        {/* Render Select Parish Dialog */}
        <SelectParish
          open={selectParishDialogOpen}
          onClose={() => setSelectParishDialogOpen(false)}
          onSelect={handleSelectParish}
        />

        {/* Edit Substation */}
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, marginTop: 4 }}>
          <TextField
            label="Substation ID"
            name="substationId"
            value={anbiam.substationId || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingSubstation}
          />

          <TextField
            label="Substation Name"
            name="substationName"
            value={anbiam.substationName || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editingSubstation}
          />

          {editingSubstation ? (
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={() => handleUpdate('substation')}>
                Update
              </Button>
              <Button variant="outlined" color="secondary" onClick={() => handleCancel('substation')}>
                Cancel
              </Button>
            </Box>
          ) : (
            <Button variant="contained" color="secondary" onClick={() => setSelectSubstationDialogOpen(true)}>
              Change Substation
            </Button>
          )}

        </Box>

        {/* Render Select Substation Dialog */}
        <SelectSubstation
          open={selectSubstationDialogOpen}
          onClose={() => setSelectSubstationDialogOpen(false)}
          onSelect={handleSelectSubstation}
        />

        <SelectFamilyDialog
          open={selectFamilyDialogOpen}
          onClose={() => setSelectFamilyDialogOpen(false)}
          onSave={handleSaveFamilies} // Pass the method to save multiple families
        />


        {/* Families Section */}
        <Typography variant="h5" gutterBottom sx={{ mt: 4 }}>
          Families
        </Typography>

        <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="families table">
          <TableHead>
            <TableRow>
              <TableCell>Family ID</TableCell>
              <TableCell>Family Card Number</TableCell>
              <TableCell>Actions</TableCell> {/* Add a column for actions */}
            </TableRow>
          </TableHead>
          <TableBody>
            {anbiam.families.map((family, index) => (
              <TableRow key={index}>
                <TableCell>{family.id}</TableCell>
                <TableCell>{family.familyCardNumber}</TableCell>
                <TableCell>
                  <Button 
                    variant="contained" 
                    color="secondary" 
                    onClick={() => handleRemoveFamily(family.id)}
                  >
                    Remove
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>

        </Table>
      </TableContainer>

        <Button sx={{ marginTop: 2 }} variant="contained" color="primary" onClick={handleAddFamily}>
          Add Family
        </Button>
      </Paper>
    </Container>
  );
}
