import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Button } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function DevoteeDetails() {
  const { devoteeId } = useParams();
  const [devotee, setDevotee] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editFields, setEditFields] = useState({});
  const [certificates, setCertificates] = useState({
    baptismCertificate: null,
    communionCertificate: null,
    confirmationCertificate: null,
    marriageCertificate: null,
    deathCertificate: null,
  });

  useEffect(() => {
    const fetchDevoteeDetails = async () => {
      try {
        const response = await axios.get(`/api/devotees/${devoteeId}`);
        setDevotee(response.data);
        setEditFields(response.data);
      } catch (error) {
        console.error('Error fetching devotee details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchDevoteeDetails();
  }, [devoteeId]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditFields((prevFields) => ({
      ...prevFields,
      [name]: value,
    }));
  };

  const handleCertificateChange = (e) => {
    const { name, files } = e.target;
    setCertificates((prevCertificates) => ({
      ...prevCertificates,
      [name]: files[0],
    }));
  };

  const handleSave = async () => {
    try {
      const formData = new FormData();

      Object.keys(editFields).forEach((key) => {
        formData.append(key, editFields[key]);
      });

      Object.keys(certificates).forEach((key) => {
        if (certificates[key]) {
          formData.append(key, certificates[key]);
        }
      });

      await axios.put(`/api/devotees/${devoteeId}`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });

      alert('Devotee details updated successfully!');
    } catch (error) {
      console.error('Error updating devotee details:', error);
      alert('Failed to update devotee details.');
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!devotee) {
    return <Typography>No devotee found.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          {devotee.name}
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Non-editable fields */}
          <Typography><strong>Family Card Number:</strong> {devotee.familyCardNumber}</Typography>

          {/* Editable fields */}
          <TextField
            label="Sex"
            name="sex"
            value={editFields.sex || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={editFields.phoneNumber || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Birth Place"
            name="birthPlace"
            value={editFields.birthPlace || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Birth Date"
            name="birthDate"
            type="date"
            value={editFields.birthDate || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Baptism Place"
            name="baptismPlace"
            value={editFields.baptismPlace || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Baptism Date"
            name="baptismDate"
            type="date"
            value={editFields.baptismDate || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Baptism Father"
            name="baptismFather"
            value={editFields.baptismFather || ''}
            onChange={handleInputChange}
            fullWidth
          />

          <Typography><strong>Baptism Certificate</strong></Typography>
            {devotee.baptismCertificate && (
              <img src={devotee.baptismCertificate} alt="Baptism Certificate" width="100%" />
            )}
            <Button variant="contained" component="label">
              Upload Baptism Certificate
              <input
                type="file"
                hidden
                name="baptismCertificate"
                onChange={handleCertificateChange}
              />
            </Button>

          {/* Continue with the remaining fields */}
          <TextField
            label="First Holy Communion Place"
            name="communionPlace"
            value={editFields.communionPlace || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="First Holy Communion Date"
            name="communionDate"
            type="date"
            value={editFields.communionDate || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="First Holy Communion Father"
            name="communionFather"
            value={editFields.communionFather || ''}
            onChange={handleInputChange}
            fullWidth
          />

          <Typography><strong>First Holy Communion Certificate</strong></Typography>
            {devotee.communionCertificate && (
              <img src={devotee.communionCertificate} alt="Communion Certificate" width="100%" />
            )}
            <Button variant="contained" component="label">
              Upload Communion Certificate
              <input
                type="file"
                hidden
                name="communionCertificate"
                onChange={handleCertificateChange}
              />
            </Button>

          <TextField
            label="Confirmation Place"
            name="confirmationPlace"
            value={editFields.confirmationPlace || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Confirmation Date"
            name="confirmationDate"
            type="date"
            value={editFields.confirmationDate || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Confirmation Father"
            name="confirmationFather"
            value={editFields.confirmationFather || ''}
            onChange={handleInputChange}
            fullWidth
          />

          <Typography><strong>Confirmation Certificate</strong></Typography>
          {devotee.confirmationCertificate && (
            <img src={devotee.confirmationCertificate} alt="Confirmation Certificate" width="100%" />
          )}
          <Button variant="contained" component="label">
            Upload Confirmation Certificate
            <input
              type="file"
              hidden
              name="confirmationCertificate"
              onChange={handleCertificateChange}
            />
          </Button>

          <TextField
            label="Spouse Name"
            name="spouseName"
            value={editFields.spouseName || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Marriage Place"
            name="marriagePlace"
            value={editFields.marriagePlace || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Marriage Date"
            name="marriageDate"
            type="date"
            value={editFields.marriageDate || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Marriage Father"
            name="marriageFather"
            value={editFields.marriageFather || ''}
            onChange={handleInputChange}
            fullWidth
          />

          <Typography><strong>Marriage Certificate</strong></Typography>
          {devotee.marriageCertificate && (
            <img src={devotee.marriageCertificate} alt="Marriage Certificate" width="100%" />
          )}
          <Button variant="contained" component="label">
            Upload Marriage Certificate
            <input
              type="file"
              hidden
              name="marriageCertificate"
              onChange={handleCertificateChange}
            />
          </Button>

          <TextField
            label="Place of Death"
            name="placeOfDeath"
            value={editFields.placeOfDeath || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Date of Death"
            name="dateOfDeath"
            type="date"
            value={editFields.dateOfDeath || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Place of Burial"
            name="placeOfBurial"
            value={editFields.placeOfBurial || ''}
            onChange={handleInputChange}
            fullWidth
          />
          <TextField
            label="Date of Burial"
            name="dateOfBurial"
            type="date"
            value={editFields.dateOfBurial || ''}
            onChange={handleInputChange}
            fullWidth
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            label="Burial Father"
            name="burialFather"
            value={editFields.burialFather || ''}
            onChange={handleInputChange}
            fullWidth
          />

          <Typography><strong>Death Certificate</strong></Typography>
          {devotee.deathCertificate && (
            <img src={devotee.deathCertificate} alt="Death Certificate" width="100%" />
          )}
          <Button variant="contained" component="label">
            Upload Death Certificate
            <input
              type="file"
              hidden
              name="deathCertificate"
              onChange={handleCertificateChange}
            />
          </Button>

          {/* Save button */}
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
