import React from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

// Dummy data for the donations
const donations = [
  { id: 1, name: 'John Doe', amount: '$100', date: '2023-01-01', type: 'church' },
  { id: 2, name: 'Jane Smith', amount: '$50', date: '2023-02-15', type: 'special' },
  { id: 3, name: 'Bob Johnson', amount: '$200', date: '2023-03-20', type: 'church' },
  { id: 4, name: 'Alice Brown', amount: '$150', date: '2023-04-05', type: 'special' },
];

const AdminDonations = () => {
  const churchDonations = donations.filter(donation => donation.type === 'church');
  const specialDonations = donations.filter(donation => donation.type === 'special');

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Admin: Donations List</Typography>

      <Typography variant="h6" sx={{ mb: 2 }}>For Church</Typography>
      <TableContainer component={Paper} sx={{ mb: 5 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {churchDonations.map((donation) => (
              <TableRow key={donation.id}>
                <TableCell>{donation.id}</TableCell>
                <TableCell>{donation.name}</TableCell>
                <TableCell>{donation.amount}</TableCell>
                <TableCell>{donation.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Typography variant="h6" sx={{ mb: 2 }}>For Special Needs</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {specialDonations.map((donation) => (
              <TableRow key={donation.id}>
                <TableCell>{donation.id}</TableCell>
                <TableCell>{donation.name}</TableCell>
                <TableCell>{donation.amount}</TableCell>
                <TableCell>{donation.date}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminDonations;
