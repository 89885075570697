import React, { useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import axios from 'axios';

const FamilyDetails = () => {
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [contact, setContact] = useState('');
  const [userId, setUserId] = useState(''); // Add a state to store the user ID

  const handleSave = () => {
    // Send the data to the backend
    axios.put(`/api/family-details/${userId}`, {
      name,
      address,
      contact,
    })
      .then(response => {
        console.log('Family details saved:', response.data);
        // Populate the fields with the saved data if necessary
        setName(response.data.name);
        setAddress(response.data.address);
        setContact(response.data.contact);
      })
      .catch(error => {
        console.error('Error saving family details:', error);
      });
  };

  return (
    <Box component="form" noValidate autoComplete="off" sx={{ '& .MuiTextField-root': { m: 1, width: '25ch' } }}>
      <div>
        <TextField label="User ID" value={userId} onChange={(e) => setUserId(e.target.value)} />
        <TextField label="Name" value={name} onChange={(e) => setName(e.target.value)} />
        <TextField label="Address" value={address} onChange={(e) => setAddress(e.target.value)} />
        <TextField label="Contact" value={contact} onChange={(e) => setContact(e.target.value)} />
      </div>
      <Button variant="contained" onClick={handleSave}>Save</Button>
    </Box>
  );
};

export default FamilyDetails;
