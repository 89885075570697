import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, Button, Table, TableBody, TableCell,
  TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import axios from 'axios';

const SelectVicarate = ({ open, onClose, onSave }) => {
  const [vicarates, setVicarates] = useState([]);

  useEffect(() => {
    const fetchVicarates = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:5000/api/vicarets');
        setVicarates(response.data);
      } catch (error) {
        console.error('Error fetching vicarates:', error);
      }
    };

    if (open) {
      fetchVicarates();
    }
  }, [open]);

  const handleSelectVicarate = (vicarate) => {
    onSave(vicarate); // Pass the selected vicarate back to the parent component
    onClose(); // Close the dialog
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Select Vicarate</DialogTitle>
      <DialogContent>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Vicarate Name</TableCell>
                <TableCell>Select</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vicarates.map((vicarate) => (
                <TableRow key={vicarate._id}>
                  <TableCell>{vicarate.name}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      onClick={() => handleSelectVicarate(vicarate)}
                    >
                      Select
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </Dialog>
  );
};

export default SelectVicarate;
