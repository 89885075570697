import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './Components/Login/Login';
import ChurchApp from './Components/ChurchApp';
import DevoteeDetails from './Components/Details/DevoteeDetails';
import FamilyDetails from './Components/Details/FamilyDetails';
import ParishDetails from './Components/Details/ParishDetails';
import SubstationDetails from './Components/Details/SubstationDetails';
import AnbiamDetails from './Components/Details/AnbiamDetails';
import VicarateDetails from './Components/Details/VicarateDetails';

function App() {
  const [userRole, setUserRole] = useState(null);
  const [parishUser, setParishUser] = useState(null); // Parish user-specific data

  const handleLogin = (role, data = null) => {
    console.log('Logging in with role:', role);
    setUserRole(role);
    
    if (role === 'parish') {
      setParishUser(data); // Set parish-specific data if role is parish
    }
  };

  const handleLogout = () => {
    console.log('Logging out');
    setUserRole(null);
    setParishUser(null); // Reset parish data on logout
  };

  return (
    <Router>
      <Routes>
        {/* Route for login */}
        <Route path="/login" element={<Login onLogin={handleLogin} />} />

        {/* Route for ChurchApp - Conditional based on userRole */}
        <Route
          path="/app"
          element={
            userRole ? (
              <ChurchApp userRole={userRole} parishUser={parishUser} onLogout={handleLogout} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />

        {/* Default route redirects to login */}
        <Route path="/" element={<Navigate to="/login" />} />

        {/* Other details pages */}
        <Route path="/devotee/:devoteeId" element={<DevoteeDetails />} />
        <Route path="/family/:familyId" element={<FamilyDetails />} />
        <Route path="/parish/:parishId" element={<ParishDetails parishUser={parishUser} />} />
        <Route path="/substation/:substationId" element={<SubstationDetails />} />
        <Route path="/anbiam/:anbiamId" element={<AnbiamDetails />} />
        <Route path="/vicarate/:vicaretId" element={<VicarateDetails />} />
      </Routes>
    </Router>
  );
}

export default App;
