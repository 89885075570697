import React, { useState } from 'react';
import {
  TextField,
  Button,
  Grid,
  Container,
  Typography,
  InputLabel,
  Select,
  MenuItem
} from '@mui/material';
import axios from 'axios';
import SelectFamilyDialog from '../Selecter/SelectFamilyDialog';

const DevoteeForm = ({parishUser}) => {
  const [formData, setFormData] = useState({
    name: '',
    familyCardNumber: '', // Added Family Card Number
    familyId: '',
    sex: '',
    photo: '',
    phoneNumber: '',
    birthPlace: '',
    birthDate: '',
    baptismPlace: '',
    baptismDate: '',
    baptismFather: '',
    baptismCertificate: '',
    communionPlace: '',
    communionDate: '',
    communionFather: '',
    communionCertificate: '',
    confirmationPlace: '',
    confirmationDate: '',
    confirmationFather: '',
    confirmationCertificate: '',
    spouseName: '',
    marriagePlace: '',
    marriageDate: '',
    marriageFather: '',
    marriageCertificate: '',
    placeOfDeath: '',
    dateOfDeath: '',
    placeOfBurial: '',
    dateOfBurial: '',
    burialFather: '',
    deathCertificate: ''
  });

  const [openFamilyDialog, setOpenFamilyDialog] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const formDataToSend = new FormData();
    for (const key in formData) {
      formDataToSend.append(key, formData[key]);
    }

    axios.post(process.env.REACT_APP_API_URL+'/devotees', formDataToSend)
      .then((response) => {
        console.log('Data submitted successfully:', response.data);
      })
      .catch((error) => {
        console.error('There was an error submitting the form!', error);
      });
  };

  // Handle opening and closing of family selection dialog
  const handleOpenFamilyDialog = () => {
    setOpenFamilyDialog(true);
  };

  const handleCloseFamilyDialog = () => {
    setOpenFamilyDialog(false);
  };

  // Handle selection of a family from the dialog
  const handleSelectFamily = (selectedFamily) => {
    if (selectedFamily) {
      setFormData({
        ...formData,
        familyCardNumber: selectedFamily.familyCardNumber,
        familyId: selectedFamily._id
      });
    }
    handleCloseFamilyDialog();
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Devotee Information Form
      </Typography>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              label="Name"
              name="name"
              fullWidth
              value={formData.name}
              onChange={handleChange}
              required
            />
          </Grid>

          {/* Button to choose family */}
          <Grid item xs={12}>
            <Button
              variant="outlined"
              onClick={handleOpenFamilyDialog}
            >
              Choose Family
            </Button>
          </Grid>

          {/* Display selected family information */}
          {formData.familyCardNumber && (
            <Grid item xs={12}>
              <Typography>
                Selected Family Card Number: {formData.familyCardNumber}
              </Typography>
            </Grid>
          )}

          {/* Hidden FamilyId Field */}
          <input
            type="hidden"
            name="familyId"
            value={formData.familyId}
            onChange={handleChange}
          />

          <Grid item xs={12}>
            <InputLabel>Sex</InputLabel>
            <Select
              name="sex"
              fullWidth
              value={formData.sex}
              onChange={handleChange}
              required
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
            </Select>
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Photo</InputLabel>
            <input
              type="file"
              name="photo"
              onChange={handleFileChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Phone Number"
              name="phoneNumber"
              fullWidth
              value={formData.phoneNumber}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Birth Place"
              name="birthPlace"
              fullWidth
              value={formData.birthPlace}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Birth Date"
              name="birthDate"
              type="date"
              fullWidth
              value={formData.birthDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Baptism Place"
              name="baptismPlace"
              fullWidth
              value={formData.baptismPlace}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Baptism Date"
              name="baptismDate"
              type="date"
              fullWidth
              value={formData.baptismDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Baptism Father"
              name="baptismFather"
              fullWidth
              value={formData.baptismFather}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Baptism Certificate</InputLabel>
            <input
              type="file"
              name="baptismCertificate"
              onChange={handleFileChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Communion Place"
              name="communionPlace"
              fullWidth
              value={formData.communionPlace}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Communion Date"
              name="communionDate"
              type="date"
              fullWidth
              value={formData.communionDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Communion Father"
              name="communionFather"
              fullWidth
              value={formData.communionFather}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Communion Certificate</InputLabel>
            <input
              type="file"
              name="communionCertificate"
              onChange={handleFileChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirmation Place"
              name="confirmationPlace"
              fullWidth
              value={formData.confirmationPlace}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirmation Date"
              name="confirmationDate"
              type="date"
              fullWidth
              value={formData.confirmationDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Confirmation Father"
              name="confirmationFather"
              fullWidth
              value={formData.confirmationFather}
              onChange={handleChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Confirmation Certificate</InputLabel>
            <input
              type="file"
              name="confirmationCertificate"
              onChange={handleFileChange}
              
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Spouse Name"
              name="spouseName"
              fullWidth
              value={formData.spouseName}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Marriage Place"
              name="marriagePlace"
              fullWidth
              value={formData.marriagePlace}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Marriage Date"
              name="marriageDate"
              type="date"
              fullWidth
              value={formData.marriageDate}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Marriage Father"
              name="marriageFather"
              fullWidth
              value={formData.marriageFather}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Marriage Certificate</InputLabel>
            <input
              type="file"
              name="marriageCertificate"
              onChange={handleFileChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Place of Death"
              name="placeOfDeath"
              fullWidth
              value={formData.placeOfDeath}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Date of Death"
              name="dateOfDeath"
              type="date"
              fullWidth
              value={formData.dateOfDeath}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Place of Burial"
              name="placeOfBurial"
              fullWidth
              value={formData.placeOfBurial}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Date of Burial"
              name="dateOfBurial"
              type="date"
              fullWidth
              value={formData.dateOfBurial}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Burial Father"
              name="burialFather"
              fullWidth
              value={formData.burialFather}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel>Death Certificate</InputLabel>
            <input
              type="file"
              name="deathCertificate"
              onChange={handleFileChange}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* Family selection dialog */}
      <SelectFamilyDialog
        open={openFamilyDialog}
        onClose={handleCloseFamilyDialog}
        onSave={(selectedFamilies) => handleSelectFamily(selectedFamilies[0])} // Select first family
        parishUser={parishUser}
      />
    </Container>
  );
};

export default DevoteeForm;
