import React, { useState } from 'react';
import { Typography, Button, TextField, MenuItem } from '@mui/material';

const churches = [
  { label: 'St. Mary\'s Church', value: 'st_marys' },
  { label: 'St. Joseph\'s Church', value: 'st_josephs' },
];

const masses = [
  { label: 'Sunday English Mass 15/6/2024 6:00AM', value: 'sunday_english_15_6_2024_6AM' },
  { label: 'Sunday Spanish Mass 15/6/2024 8:00AM', value: 'sunday_spanish_15_6_2024_8AM' },
];

export default function MassIntention() {
  const [church, setChurch] = useState('');
  const [mass, setMass] = useState('');
  const [intention, setIntention] = useState('');
  const [amount, setAmount] = useState('');

  const handlePayment = () => {
    // Payment logic here
    console.log('Payment initiated', { church, mass, intention, amount });
  };

  return (
    <div>
      <Typography variant="h4">Mass Intention</Typography>
      <TextField
        select
        label="Church"
        value={church}
        onChange={(e) => setChurch(e.target.value)}
        fullWidth
        margin="normal"
      >
        {churches.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        select
        label="Mass"
        value={mass}
        onChange={(e) => setMass(e.target.value)}
        fullWidth
        margin="normal"
      >
        {masses.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
      <TextField
        label="Intention"
        value={intention}
        onChange={(e) => setIntention(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Amount"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" color="primary" onClick={handlePayment}>
        Pay Now
      </Button>
    </div>
  );
}
