import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  Typography
} from '@mui/material';

const SelectParish = ({ open, onClose, onSelect }) => {
  const [parishes, setParishes] = useState([]);

  useEffect(() => {
    const fetchParishes = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/parishes');
        const data = await response.json();
        setParishes(data);
      } catch (error) {
        console.error('Error fetching parishes:', error);
      }
    };

    if (open) {
      fetchParishes();
    }
  }, [open]);

  const handleSelect = (parish) => {
    onSelect(parish);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select a Parish</DialogTitle>
      <DialogContent>
        <Box sx={{ my: 2 }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="parish table">
              <TableHead>
                <TableRow>
                  <TableCell>Parish Name</TableCell>
                  <TableCell>Father's Name</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Address</TableCell>
                  <TableCell>Google Map Link</TableCell>
                  <TableCell>Select</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {parishes.map((parish) => (
                  <TableRow key={parish._id}>
                    <TableCell>{parish.name}</TableCell>
                    <TableCell>{parish.fatherName}</TableCell>
                    <TableCell>{parish.fatherPhone}</TableCell>
                    <TableCell>
                      {parish.address?.line1 || 'N/A'}
                      <br />
                      {parish.address?.line2 || ''}
                      <br />
                      {parish.address?.line3 || ''}
                    </TableCell>
                    <TableCell>
                      <a href={parish.googleMapLink} target="_blank" rel="noopener noreferrer">
                        View on Google Maps
                      </a>
                    </TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleSelect(parish)}>
                        Select
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default SelectParish;
