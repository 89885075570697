import React from 'react';
import { Card, CardContent, Typography, Grid, List, ListItem, ListItemText } from '@mui/material';
import 'animate.css';

const ParishProfile = ({ parishUser }) => {
  // Check if parishUser is available before rendering the profile
  if (!parishUser) {
    return <Typography>Loading...</Typography>;
  }

  const {
    name,
    fatherName,
    fatherPhone,
    userId,
    subStations = [],
    anbiam = [],
    vicarateName,
  } = parishUser; // Destructure parish data with default values

  return (
    <Grid container spacing={3} justifyContent="center">
      <Grid item xs={12} md={8}>
        {/* Apply FadeIn animation to the Card */}
        <Card className="animate__animated animate__fadeIn">
          <CardContent>
            {/* Parish Info */}
            <Typography variant="h5" gutterBottom>
              Parish Profile
            </Typography>
            <Typography variant="body1">
              <strong>Name of Parish:</strong> {name}
            </Typography>
            <Typography variant="body1">
              <strong>Name of Father:</strong> {fatherName || 'Not Available'}
            </Typography>
            <Typography variant="body1">
              <strong>Father's Phone:</strong> {fatherPhone || 'Not Available'}
            </Typography>
            <Typography variant="body1">
              <strong>User ID:</strong> {userId}
            </Typography>
            <Typography variant="body1">
              <strong>Vicarate:</strong> {vicarateName}
            </Typography>

            {/* Sub-Stations Section */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
              Sub-Stations
            </Typography>
            {subStations.length > 0 ? (
              <List>
                {subStations.map((station, index) => (
                  <ListItem key={index} className="animate__animated animate__bounceIn">
                    <ListItemText primary={station.name} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No Sub-Stations available</Typography>
            )}

            {/* Anbiam Section */}
            <Typography variant="h6" gutterBottom style={{ marginTop: '20px' }}>
              Anbiam
            </Typography>
            {anbiam.length > 0 ? (
              <List>
                {anbiam.map((anb, index) => (
                  <ListItem key={index} className="animate__animated animate__bounceIn">
                    <ListItemText primary={anb.anbiamName} />
                  </ListItem>
                ))}
              </List>
            ) : (
              <Typography variant="body2">No Anbiam available</Typography>
            )}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ParishProfile;
