import React, { useState, useEffect } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  IconButton,
  TextField,
  Button
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import axios from 'axios';

const AdminFamilyDetails = () => {
  const [familyDetails, setFamilyDetails] = useState([]);
  const [editIdx, setEditIdx] = useState(null);
  const [editedData, setEditedData] = useState({ id: '', name: '', address: '', contact: '' });

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_URL}/family-details`)
      .then(response => setFamilyDetails(response.data))
      .catch(error => console.error('Error fetching family details:', error));
  }, []);

  const handleEdit = (idx) => {
    setEditIdx(idx);
    setEditedData(familyDetails[idx]);
  };

  const handleSave = () => {
    const updatedDetails = [...familyDetails];
    updatedDetails[editIdx] = editedData;
    setFamilyDetails(updatedDetails);
    setEditIdx(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditedData((prev) => ({ ...prev, [name]: value }));
  };

  return (
    <Box sx={{ padding: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Admin: Family Details List</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Address</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {familyDetails.map((detail, idx) => (
              <TableRow key={detail.id}>
                <TableCell>{detail.id}</TableCell>
                <TableCell>
                  {editIdx === idx ? (
                    <TextField
                      name="name"
                      value={editedData.name}
                      onChange={handleChange}
                    />
                  ) : (
                    detail.name
                  )}
                </TableCell>
                <TableCell>
                  {editIdx === idx ? (
                    <TextField
                      name="address"
                      value={editedData.address}
                      onChange={handleChange}
                    />
                  ) : (
                    detail.address
                  )}
                </TableCell>
                <TableCell>
                  {editIdx === idx ? (
                    <TextField
                      name="contact"
                      value={editedData.contact}
                      onChange={handleChange}
                    />
                  ) : (
                    detail.contact
                  )}
                </TableCell>
                <TableCell>
                  {editIdx === idx ? (
                    <Button
                      startIcon={<SaveIcon />}
                      onClick={handleSave}
                      color="primary"
                    >
                      Save
                    </Button>
                  ) : (
                    <IconButton
                      onClick={() => handleEdit(idx)}
                      color="primary"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default AdminFamilyDetails;
