import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';

const Notices = () => {
  const noticesData = [
    { id: 1, title: 'Christmas Mass', description: 'Join us for a special Christmas mass.' },
    { id: 2, title: 'Easter Celebration', description: 'Celebrate Easter with us.' },
  ];

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      {noticesData.map(notice => (
        <Card key={notice.id} sx={{ width: '80%', m: 1 }}>
          <CardContent>
            <Typography variant="h5">{notice.title}</Typography>
            <Typography variant="body2">{notice.description}</Typography>
          </CardContent>
        </Card>
      ))}
    </Box>
  );
};

export default Notices;
