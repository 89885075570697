import React, { useState } from 'react';
import { TextField, Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login({ onLogin }) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError(''); // Clear any previous errors

    // Hardcoded admin, user, and operator login logic
    if (username === 'admin' && password === 'admin') {
      console.log('Admin login');
      onLogin('admin');
      navigate('/app');
    } else if (username === 'user' && password === 'user') {
      console.log('User login');
      onLogin('user');
      navigate('/app');
    } else if (username === 'op' && password === 'op') {
      console.log('Operator login');
      onLogin('op');
      navigate('/app');
    } else {
      // Handle vicarate login via API
      /***try {
        const vicarateResponse = await axios.post('/api/login/vicarate', {
          username: username,
          password: password,
        });
        if (vicarateResponse.status === 200) {
          console.log('Vicarate login successful', vicarateResponse.data);
          onLogin('vicarate', vicarateResponse.data.vicarate);
          navigate('/app');
          return;
        }
      } catch (error) {
        console.error('Vicarate login failed', error);
        setError('Invalid Vicarate credentials');
      }***/

      // Handle parish login via API
      try {
        const parishResponse = await axios.post('/api/login/parish', {
          userId: username,
          password: password,
        });
        if (parishResponse.status === 200) {
          console.log('Parish login successful', parishResponse.data);
          onLogin('parish', parishResponse.data.parish); // Pass the parish data to onLogin
          navigate('/app');
          return;
        }
      } catch (error) {
        console.error('Parish login failed', error);
        setError('Invalid Parish credentials');
      }
    }
  };

  return (
    <Container maxWidth="xs">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <Typography variant="h4" gutterBottom>
          Church App Login
        </Typography>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Username"
            variant="outlined"
            margin="normal"
            fullWidth
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <TextField
            label="Password"
            type="password"
            variant="outlined"
            margin="normal"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography color="error" variant="body2" align="center">
              {error}
            </Typography>
          )}
          <Button type="submit" variant="contained" color="primary" fullWidth>
            Login
          </Button>
        </form>
      </Box>
    </Container>
  );
}

export default Login;
