import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Box, TextField, Button, Typography, MenuItem, InputLabel, Select, FormControl, Paper } from '@mui/material';

const AddFamily = () => {
  const { control, handleSubmit, setValue } = useForm();
  const [familyPhoto, setFamilyPhoto] = useState(null);
  const [fatherPhoto, setFatherPhoto] = useState(null);
  const [motherPhoto, setMotherPhoto] = useState(null);
  const [children, setChildren] = useState([]);
  const [grandparents, setGrandparents] = useState([]);

  const handleFileChange = (e, setFile) => {
    const file = e.target.files[0];
    if (file) {
      setFile(URL.createObjectURL(file));
    }
  };

  const addChild = () => {
    setChildren([...children, {}]);
  };

  const addGrandparent = () => {
    setGrandparents([...grandparents, {}]);
  };

  const onSubmit = (data) => {
    console.log('Form Data:', data);
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Admin: Add Family</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">Family Details</Typography>
          <Controller
            name="familyHead"
            control={control}
            render={({ field }) => <TextField label="Family Head" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="familyNumber"
            control={control}
            render={({ field }) => <TextField label="Family Number" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Place</InputLabel>
            <Controller
              name="place"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="place1">Place 1</MenuItem>
                  <MenuItem value="place2">Place 2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Anbiyam</InputLabel>
            <Controller
              name="anbiyam"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="anbiyam1">Anbiyam 1</MenuItem>
                  <MenuItem value="anbiyam2">Anbiyam 2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <Typography>Family Photo</Typography>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Upload File
            <input type="file" hidden onChange={(e) => handleFileChange(e, setFamilyPhoto)} />
          </Button>
          {familyPhoto && <img src={familyPhoto} alt="Family" style={{ width: '100px', height: '100px', mb: 2 }} />}
          <Typography>Address</Typography>
          <Controller
            name="addressLine1"
            control={control}
            render={({ field }) => <TextField label="Address Line 1" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="landmark"
            control={control}
            render={({ field }) => <TextField label="Landmark" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="streetName"
            control={control}
            render={({ field }) => <TextField label="Street Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="districtName"
            control={control}
            defaultValue="Coimbatore"
            render={({ field }) => <TextField label="District Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="stateName"
            control={control}
            defaultValue="Tamil Nadu"
            render={({ field }) => <TextField label="State Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="countryName"
            control={control}
            defaultValue="India"
            render={({ field }) => <TextField label="Country Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="mobileNumber"
            control={control}
            render={({ field }) => <TextField label="Mobile Number" fullWidth sx={{ mb: 2 }} {...field} />}
          />
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">Father's Details</Typography>
          <Controller
            name="fatherName"
            control={control}
            render={({ field }) => <TextField label="Father Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherDob"
            control={control}
            render={({ field }) => <TextField label="Date of Birth" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherPlaceOfBirth"
            control={control}
            render={({ field }) => <TextField label="Place of Birth" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherBaptismDate"
            control={control}
            render={({ field }) => <TextField label="Date of Baptism" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherBaptismPlace"
            control={control}
            render={({ field }) => <TextField label="Place of Baptism" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherCommunionDate"
            control={control}
            render={({ field }) => <TextField label="Date of Communion" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherCommunionPlace"
            control={control}
            render={({ field }) => <TextField label="Place of Communion" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherEducation"
            control={control}
            render={({ field }) => <TextField label="Education" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Business/Profession</InputLabel>
            <Controller
              name="fatherProfession"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="unemployed">Unemployed</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="profession1">Profession 1</MenuItem>
                  <MenuItem value="profession2">Profession 2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <Controller
            name="fatherAnnualIncome"
            control={control}
            render={({ field }) => <TextField label="Annual Income" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="marriageDate"
            control={control}
            render={({ field }) => <TextField label="Marriage Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="marriagePlace"
            control={control}
            render={({ field }) => <TextField label="Marriage Place" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="fatherDeathDate"
            control={control}
            render={({ field }) => <TextField label="Death Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Typography>Father's Photo</Typography>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Upload File
            <input type="file" hidden onChange={(e) => handleFileChange(e, setFatherPhoto)} />
          </Button>
          {fatherPhoto && <img src={fatherPhoto} alt="Father" style={{ width: '100px', height: '100px', mb: 2 }} />}
          <Controller
            name="fatherBloodGroup"
            control={control}
            render={({ field }) => <TextField label="Blood Group" fullWidth sx={{ mb: 2 }} {...field} />}
          />
        </Paper>

        <Paper sx={{ p: 3, mb: 3 }}>
          <Typography variant="h6">Mother's Details</Typography>
          <Controller
            name="motherName"
            control={control}
            render={({ field }) => <TextField label="Mother Name" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherDob"
            control={control}
            render={({ field }) => <TextField label="Date of Birth" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherPlaceOfBirth"
            control={control}
            render={({ field }) => <TextField label="Place of Birth" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherBaptismDate"
            control={control}
            render={({ field }) => <TextField label="Date of Baptism" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherBaptismPlace"
            control={control}
            render={({ field }) => <TextField label="Place of Baptism" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherCommunionDate"
            control={control}
            render={({ field }) => <TextField label="Date of Communion" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherCommunionPlace"
            control={control}
            render={({ field }) => <TextField label="Place of Communion" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Controller
            name="motherEducation"
            control={control}
            render={({ field }) => <TextField label="Education" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Business/Profession</InputLabel>
            <Controller
              name="motherProfession"
              control={control}
              render={({ field }) => (
                <Select {...field}>
                  <MenuItem value="unemployed">Unemployed</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                  <MenuItem value="profession1">Profession 1</MenuItem>
                  <MenuItem value="profession2">Profession 2</MenuItem>
                </Select>
              )}
            />
          </FormControl>
          <Controller
            name="motherAnnualIncome"
            control={control}
            render={({ field }) => <TextField label="Annual Income" fullWidth sx={{ mb: 2 }} {...field} />}
          />
          <Typography>Mother's Photo</Typography>
          <Button variant="contained" component="label" sx={{ mb: 2 }}>
            Upload File
            <input type="file" hidden onChange={(e) => handleFileChange(e, setMotherPhoto)} />
          </Button>
          {motherPhoto && <img src={motherPhoto} alt="Mother" style={{ width: '100px', height: '100px', mb: 2 }} />}
          <Controller
            name="motherBloodGroup"
            control={control}
            render={({ field }) => <TextField label="Blood Group" fullWidth sx={{ mb: 2 }} {...field} />}
          />
        </Paper>

        {children.map((child, index) => (
          <Paper sx={{ p: 3, mb: 3 }} key={index}>
            <Typography variant="h6">Child {index + 1} Details</Typography>
            <Controller
              name={`children[${index}].name`}
              control={control}
              render={({ field }) => <TextField label="Name" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].gender`}
              control={control}
              render={({ field }) => <TextField label="Gender" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].dob`}
              control={control}
              render={({ field }) => <TextField label="Date of Birth" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].placeOfBirth`}
              control={control}
              render={({ field }) => <TextField label="Place of Birth" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].baptismDate`}
              control={control}
              render={({ field }) => <TextField label="Date of Baptism" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].baptismPlace`}
              control={control}
              render={({ field }) => <TextField label="Place of Baptism" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].communionDate`}
              control={control}
              render={({ field }) => <TextField label="Date of Communion" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].communionPlace`}
              control={control}
              render={({ field }) => <TextField label="Place of Communion" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].education`}
              control={control}
              render={({ field }) => <TextField label="Education" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Business/Profession</InputLabel>
              <Controller
                name={`children[${index}].profession`}
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="unemployed">Unemployed</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="profession1">Profession 1</MenuItem>
                    <MenuItem value="profession2">Profession 2</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name={`children[${index}].annualIncome`}
              control={control}
              render={({ field }) => <TextField label="Annual Income" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].marriageDate`}
              control={control}
              render={({ field }) => <TextField label="Marriage Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].marriagePlace`}
              control={control}
              render={({ field }) => <TextField label="Marriage Place" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`children[${index}].deathDate`}
              control={control}
              render={({ field }) => <TextField label="Death Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Typography>Child's Photo</Typography>
            <Button variant="contained" component="label" sx={{ mb: 2 }}>
              Upload File
              <input type="file" hidden onChange={(e) => handleFileChange(e, (file) => {
                const updatedChildren = [...children];
                updatedChildren[index] = { ...updatedChildren[index], photo: file };
                setChildren(updatedChildren);
              })} />
            </Button>
            {child.photo && <img src={child.photo} alt={`Child ${index + 1}`} style={{ width: '100px', height: '100px', mb: 2 }} />}
            <Controller
              name={`children[${index}].bloodGroup`}
              control={control}
              render={({ field }) => <TextField label="Blood Group" fullWidth sx={{ mb: 2 }} {...field} />}
            />
          </Paper>
        ))}

        {grandparents.map((grandparent, index) => (
          <Paper sx={{ p: 3, mb: 3 }} key={index}>
            <Typography variant="h6">Grandparent {index + 1} Details</Typography>
            <Controller
              name={`grandparents[${index}].name`}
              control={control}
              render={({ field }) => <TextField label="Name" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].gender`}
              control={control}
              render={({ field }) => <TextField label="Gender" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].dob`}
              control={control}
              render={({ field }) => <TextField label="Date of Birth" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].placeOfBirth`}
              control={control}
              render={({ field }) => <TextField label="Place of Birth" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].baptismDate`}
              control={control}
              render={({ field }) => <TextField label="Date of Baptism" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].baptismPlace`}
              control={control}
              render={({ field }) => <TextField label="Place of Baptism" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].communionDate`}
              control={control}
              render={({ field }) => <TextField label="Date of Communion" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].communionPlace`}
              control={control}
              render={({ field }) => <TextField label="Place of Communion" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].education`}
              control={control}
              render={({ field }) => <TextField label="Education" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Business/Profession</InputLabel>
              <Controller
                name={`grandparents[${index}].profession`}
                control={control}
                render={({ field }) => (
                  <Select {...field}>
                    <MenuItem value="unemployed">Unemployed</MenuItem>
                    <MenuItem value="other">Other</MenuItem>
                    <MenuItem value="profession1">Profession 1</MenuItem>
                    <MenuItem value="profession2">Profession 2</MenuItem>
                  </Select>
                )}
              />
            </FormControl>
            <Controller
              name={`grandparents[${index}].annualIncome`}
              control={control}
              render={({ field }) => <TextField label="Annual Income" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].marriageDate`}
              control={control}
              render={({ field }) => <TextField label="Marriage Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].marriagePlace`}
              control={control}
              render={({ field }) => <TextField label="Marriage Place" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Controller
              name={`grandparents[${index}].deathDate`}
              control={control}
              render={({ field }) => <TextField label="Death Date" type="date" fullWidth sx={{ mb: 2 }} {...field} />}
            />
            <Typography>Grandparent's Photo</Typography>
            <Button variant="contained" component="label" sx={{ mb: 2 }}>
              Upload File
              <input type="file" hidden onChange={(e) => handleFileChange(e, (file) => {
                const updatedGrandparents = [...grandparents];
                updatedGrandparents[index] = { ...updatedGrandparents[index], photo: file };
                setGrandparents(updatedGrandparents);
              })} />
            </Button>
            {grandparent.photo && <img src={grandparent.photo} alt={`Grandparent ${index + 1}`} style={{ width: '100px', height: '100px', mb: 2 }} />}
            <Controller
              name={`grandparents[${index}].bloodGroup`}
              control={control}
              render={({ field }) => <TextField label="Blood Group" fullWidth sx={{ mb: 2 }} {...field} />}
            />
          </Paper>
        ))}

        <Button variant="contained" color="primary" onClick={addChild} sx={{ mb: 2 }}>
          Add Child
        </Button>
        <Button variant="contained" color="primary" onClick={addGrandparent} sx={{ mb: 2 }}>
          Add Grandparent
        </Button>

        <Button type="submit" variant="contained" color="primary">
          Submit
        </Button>
      </form>
    </Box>
  );
};

export default AddFamily;
