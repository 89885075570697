import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Button, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios';

export default function FamilyDetails() {
  const { familyId } = useParams(); // Get the family _id from the URL params
  const [family, setFamily] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editMode, setEditMode] = useState(false); // Add edit mode state

  useEffect(() => {
    const fetchFamilyDetails = async () => {
      try {
        if (familyId) {
          const response = await axios.get(`/api/families/${familyId}`);
          setFamily(response.data);
        } else {
          console.error('Family ID is undefined');
        }
      } catch (error) {
        console.error('Error fetching family details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchFamilyDetails();
  }, [familyId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFamily({ ...family, [name]: value });
  };

  const handleSave = async () => {
    try {
      await axios.put(`/api/families/${familyId}`, family); // API call to update family details
      setEditMode(false);
    } catch (error) {
      console.error('Error updating family details:', error);
    }
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!family) {
    return <Typography>No family found.</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Family Details
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Family Card Number"
            value={family.familyCardNumber}
            InputProps={{
              readOnly: true,
            }}
            variant="outlined"
            fullWidth
            margin="normal"
            helperText="Warning: Changing this would affect related data!"
          />

          <TextField
            label="Family Head Name"
            name="familyHeadName"
            value={family.familyHeadName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <Box sx={{ my: 2 }}>
            <Typography variant="h6">Family Photo</Typography>
            <img src={family.familyPhoto} alt="Family" style={{ width: '200px', height: 'auto' }} />
            {editMode && (
              <Button variant="contained" component="label" sx={{ mt: 2 }}>
                Upload New Photo
                <input type="file" hidden />
              </Button>
            )}
          </Box>

          <Typography variant="h6" gutterBottom>
            Family Members
          </Typography>
          <ul>
            {(family.familyMembers?.length ? family.familyMembers : []).map((member) => (
              <li key={member.id}>
                <Link href={`/devotee/${member.id}`} target="_blank">
                  {member.name || 'Unnamed Member'}
                </Link>
              </li>
            ))}
          </ul>

          <TextField
            label="Anbiam ID"
            name="anbiamId"
            value={family.anbiamId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <TextField
            label="Anbiam Name"
            name="anbiamName"
            value={family.anbiamName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <TextField
            label="Email"
            name="email"
            value={family.email}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <TextField
            label="Phone Number"
            name="phoneNumber"
            value={family.phoneNumber}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <Typography variant="h6" gutterBottom>
            Address
          </Typography>
          <TextField
            label="Line 1"
            name="line1"
            value={family.address?.line1 || ''}
            onChange={(e) => setFamily({ ...family, address: { ...family.address, line1: e.target.value } })}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />
          <TextField
            label="Line 2"
            name="line2"
            value={family.address?.line2 || ''}
            onChange={(e) => setFamily({ ...family, address: { ...family.address, line2: e.target.value } })}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />
          <TextField
            label="Line 3"
            name="line3"
            value={family.address?.line3 || ''}
            onChange={(e) => setFamily({ ...family, address: { ...family.address, line3: e.target.value } })}
            variant="outlined"
            fullWidth
            margin="normal"
            disabled={!editMode} // Disable in view mode
          />

          <Box sx={{ mt: 2 }}>
            <Link href={family.googleMapLink} target="_blank" rel="noopener">
              Show in Map
            </Link>
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => navigator.clipboard.writeText(family.googleMapLink)}
            >
              Copy Link
            </Button>
          </Box>

          {!editMode ? (
            <Button variant="contained" sx={{ mt: 2 }} onClick={() => setEditMode(true)}>
              Edit
            </Button>
          ) : (
            <Box sx={{ mt: 2, display: 'flex', gap: 2 }}>
              <Button variant="contained" color="primary" onClick={handleSave}>
                Save
              </Button>
              <Button variant="outlined" onClick={() => setEditMode(false)}>
                Cancel
              </Button>
            </Box>
          )}
        </Box>
      </Paper>
    </Container>
  );
}
