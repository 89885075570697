import React, { useState } from 'react';
import { TextField, Button, IconButton, Typography, Box } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import axios from 'axios';
import SelectParish from '../Selecter/SelectParish'; // Import Parish Selector Dialog

function CreateVicaret() {
  const [vicaretData, setVicaretData] = useState({
    name: '',
    fatherName: '',
    fatherPhone: '',
    username: '',
    password: '',
    parishes: [{ id: '', name: '' }], // Store parish id and name
  });

  const [isParishDialogOpen, setParishDialogOpen] = useState(false);
  const [parishIndex, setParishIndex] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVicaretData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleAddParish = () => {
    setVicaretData((prevData) => ({
      ...prevData,
      parishes: [...prevData.parishes, { id: '', name: '' }],
    }));
  };

  const handleRemoveParish = (index) => {
    setVicaretData((prevData) => ({
      ...prevData,
      parishes: prevData.parishes.filter((_, i) => i !== index),
    }));
  };

  const handleParishDialogOpen = (index) => {
    setParishIndex(index);
    setParishDialogOpen(true);
  };

  const handleParishDialogClose = () => {
    setParishDialogOpen(false);
  };

  const handleParishSelect = (parish) => {
    const newParishes = vicaretData.parishes.map((p, i) =>
      i === parishIndex ? { id: parish._id, name: parish.name } : p
    );
    setVicaretData((prevData) => ({ ...prevData, parishes: newParishes }));
    setParishDialogOpen(false);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/vicaret', vicaretData);
      console.log('Vicaret created successfully:', response.data);
    } catch (error) {
      console.error('There was an error creating the vicaret!', error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>Create Vicaret</Typography>

      <TextField
        label="Name of Vicaret"
        name="name"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={vicaretData.name}
        onChange={handleChange}
      />
      <TextField
        label="Name of Father"
        name="fatherName"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={vicaretData.fatherName}
        onChange={handleChange}
      />
      <TextField
        label="Phone No of Father"
        name="fatherPhone"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={vicaretData.fatherPhone}
        onChange={handleChange}
      />
      <TextField
        label="Username"
        name="username"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={vicaretData.username}
        onChange={handleChange}
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={vicaretData.password}
        onChange={handleChange}
      />

      <Typography variant="h6">List of Parishes</Typography>
      {vicaretData.parishes.map((parish, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Typography sx={{ flexGrow: 1 }}>
            {parish.name ? `Parish Name: ${parish.name} (ID: ${parish.id})` : 'No Parish Selected'}
          </Typography>
          <Button
            variant="outlined"
            onClick={() => handleParishDialogOpen(index)}
            sx={{ mr: 2 }}
          >
            Select Parish
          </Button>
          <IconButton onClick={() => handleRemoveParish(index)} color="error">
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddCircleOutline />}
        onClick={handleAddParish}
        sx={{ mb: 2 }}
      >
        Add Parish
      </Button>

      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit Vicaret Data
      </Button>

      {/* Parish Selector Dialog */}
      <SelectParish
        open={isParishDialogOpen}
        onClose={handleParishDialogClose}
        onSelect={handleParishSelect}
      />
    </Box>
  );
}

export default CreateVicaret;
