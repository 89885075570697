import React, { useState } from 'react';
import { Box, TextField, Button, Card, CardContent, Typography, Paper } from '@mui/material';

const AdminNotices = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [notices, setNotices] = useState([
    { id: 1, title: 'Christmas Mass', description: 'Join us for a special Christmas mass.' },
    { id: 2, title: 'Easter Celebration', description: 'Celebrate Easter with us.' },
  ]);

  const handleAddNotice = () => {
    if (title && description) {
      const newNotice = { id: notices.length + 1, title, description };
      setNotices([...notices, newNotice]);
      setTitle('');
      setDescription('');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Admin: Manage Notices</Typography>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6">Add New Notice</Typography>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          sx={{ mb: 2 }}
        />
        <Button variant="contained" color="primary" onClick={handleAddNotice}>Add Notice</Button>
      </Paper>
      <Typography variant="h6">Published Notices</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {notices.map(notice => (
          <Card key={notice.id} sx={{ width: '80%', m: 1 }}>
            <CardContent>
              <Typography variant="h5">{notice.title}</Typography>
              <Typography variant="body2">{notice.description}</Typography>
            </CardContent>
          </Card>
        ))}
      </Box>
    </Box>
  );
};

export default AdminNotices;
