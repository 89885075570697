import React, { useState } from 'react';
import { Box, TextField, Button, Paper, Typography, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const AdminSchedule = () => {
  const [title, setTitle] = useState('');
  const [date, setDate] = useState(new Date());
  const [time, setTime] = useState('');
  const [recurrence, setRecurrence] = useState('');
  const [schedule, setSchedule] = useState([
    { id: 1, title: 'Sunday Mass', date: new Date('2024-06-16T10:00:00'), recurrence: 'weekly' },
    { id: 2, title: 'Wednesday Prayer', date: new Date('2024-06-19T19:00:00'), recurrence: 'weekly' },
  ]);

  const handleAddEvent = () => {
    if (title && date && time && recurrence) {
      const newEvent = { id: schedule.length + 1, title, date: new Date(date.setHours(...time.split(':'))), recurrence };
      setSchedule([...schedule, newEvent]);
      setTitle('');
      setDate(new Date());
      setTime('');
      setRecurrence('');
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Admin: Manage Schedule</Typography>
      <Paper sx={{ p: 2, mb: 3 }}>
        <Typography variant="h6">Add New Event</Typography>
        <TextField
          label="Title"
          variant="outlined"
          fullWidth
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          sx={{ mb: 2 }}
        />
        <DatePicker
          selected={date}
          onChange={(date) => setDate(date)}
          dateFormat="MMMM d, yyyy"
          customInput={<TextField label="Date" variant="outlined" fullWidth sx={{ mb: 2 }} />}
        />
        <TextField
          label="Time"
          type="time"
          variant="outlined"
          fullWidth
          value={time}
          onChange={(e) => setTime(e.target.value)}
          sx={{ mb: 2 }}
        />
        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel id="recurrence-label">Recurrence</InputLabel>
          <Select
            labelId="recurrence-label"
            value={recurrence}
            onChange={(e) => setRecurrence(e.target.value)}
          >
            <MenuItem value="daily">Daily</MenuItem>
            <MenuItem value="every-2-days">Every 2 Days</MenuItem>
            <MenuItem value="weekly">Weekly</MenuItem>
          </Select>
        </FormControl>
        <Button variant="contained" color="primary" onClick={handleAddEvent}>Add Event</Button>
      </Paper>
      <Typography variant="h6">Scheduled Events</Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {schedule.map(event => (
          <Paper key={event.id} sx={{ width: '80%', m: 1, p: 2 }}>
            <Typography variant="h6">{event.title}</Typography>
            <Typography variant="body2">{event.date.toDateString()} at {event.date.toLocaleTimeString()}</Typography>
            <Typography variant="body2">Recurrence: {event.recurrence}</Typography>
          </Paper>
        ))}
      </Box>
    </Box>
  );
};

export default AdminSchedule;
