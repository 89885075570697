// src/Components/VicarateDetails.js

import React, { useEffect, useState } from 'react';
import {
  Container, Typography, Paper, Box, TextField, Button, Link,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Divider, IconButton, InputAdornment
} from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import axios from 'axios';

const VicarateDetails = () => {
  const { vicaretId } = useParams();
  const [vicaret, setVicaret] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchVicarateDetails = async () => {
      try {
        const response = await axios.get(`http://127.0.0.1:5000/api/vicaret/${vicaretId}`);
        setVicaret(response.data);
      } catch (err) {
        console.error('Error fetching vicaret details:', err);
        setError('Failed to load vicaret details.');
      }
    };

    fetchVicarateDetails();
  }, [vicaretId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVicaret({ ...vicaret, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!vicaret) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Vicaret Details
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Name of Vicaret"
            name="name"
            value={vicaret.name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Name of Father"
            name="fatherName"
            value={vicaret.fatherName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Phone Number of Father"
            name="fatherPhone"
            value={vicaret.fatherPhone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Username"
            name="username"
            value={vicaret.username}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          {/* Password Field */}
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={vicaret.password || ''}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ mt: 2 }}>
            {/* Add any additional links or actions here */}
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Parishes List */}
        <Typography variant="h5" gutterBottom>
          Parishes Assigned
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="parish table">
            <TableHead>
              <TableRow>
                <TableCell>Parish Name</TableCell>
                {/* Add more parish-related fields if necessary */}
              </TableRow>
            </TableHead>
            <TableBody>
              {vicaret.parishes && vicaret.parishes.length > 0 ? (
                vicaret.parishes.map((parish, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <RouterLink to={`/parish/${parish._id || parish.id}`}>
                        {parish.name}
                      </RouterLink>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell>No Parishes Assigned</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
};

export default VicarateDetails;
