// src/Components/VicarateList.js

import React, { useState, useEffect } from 'react';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Container, Link, Box, Typography, Button
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

const VicarateList = () => {
  const [vicarets, setVicarets] = useState([]);

  useEffect(() => {
    const fetchVicarets = async () => {
      try {
        const response = await axios.get('http://127.0.0.1:5000/api/vicarets');
        setVicarets(response.data);
      } catch (error) {
        console.error('Error fetching vicarets:', error);
      }
    };

    fetchVicarets();
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Vicaret List
        </Typography>
        <Button
          variant="contained"
          color="primary"
          component={RouterLink}
          to="/create-vicaret"
          sx={{ mb: 2 }}
        >
          Create New Vicaret
        </Button>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="vicaret table">
            <TableHead>
              <TableRow>
                <TableCell>Vicaret Name</TableCell>
                <TableCell>Father's Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Username</TableCell>
                <TableCell>Parishes</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {vicarets.map((vicaret) => (
                <TableRow key={vicaret._id}>
                  <TableCell>
                    <RouterLink to={`/vicaret/${vicaret._id}`}>
                      {vicaret.name}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{vicaret.fatherName}</TableCell>
                  <TableCell>{vicaret.fatherPhone}</TableCell>
                  <TableCell>{vicaret.username}</TableCell>
                  <TableCell>
                    {vicaret.parishes && vicaret.parishes.length > 0 ? (
                      vicaret.parishes.map((parish, index) => (
                        <div key={index}>
                          <RouterLink to={`/parish/${parish._id || parish.id}`}>
                            {parish.name}
                          </RouterLink>
                        </div>
                      ))
                    ) : (
                      'No Parishes Assigned'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default VicarateList;
