import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Box, Typography, Button } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const AnbiamList = ({ parishUser }) => {
  const [anbiams, setAnbiams] = useState([]);

  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/anbiams');
        const data = await response.json();
        setAnbiams(data);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };

    fetchAnbiams();
  }, []);

  

  // Filter anbiams based on parishUser's parish name
  var filteredAnbiams=anbiams
  if(parishUser)
  {
    filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  }

  if(!parishUser)
  {
    filteredAnbiams = anbiams
  }

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Anbiam List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="anbiam table">
            <TableHead>
              <TableRow>
                <TableCell>Anbiam Name</TableCell>
                <TableCell>Representative Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Families</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredAnbiams.map((anbiam) => (
                <TableRow key={anbiam._id}>
                  <TableCell>
                    <RouterLink to={`/anbiam/${anbiam._id}`}>
                      {anbiam.anbiamName}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{anbiam.representativeName || 'Not Available'}</TableCell>
                  <TableCell>{anbiam.phoneNo || 'Not Available'}</TableCell>
                  <TableCell>
                    <ul>
                      {anbiam.families.map((family, i) => (
                        <li key={i}>
                          <Typography variant="body2" color="text.secondary">
                            Family Card: {family.familyCardNumber || 'No Family Listed'}
                          </Typography>
                        </li>
                      ))}
                    </ul>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default AnbiamList;
