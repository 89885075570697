import React from 'react';
import { Typography, Button } from '@mui/material';

export default function Subscription() {
  // Example subscription data
  const pendingSubscriptions = [
    { month: 'June', status: 'Pending' },
    { month: 'July', status: 'Pending' }
  ];

  const handlePayment = () => {
    // Payment logic here
    console.log('Payment initiated');
  };

  return (
    <div>
      <Typography variant="h4">Your Subscriptions</Typography>
      <ul>
        {pendingSubscriptions.map((subscription, index) => (
          <li key={index}>
            <Typography variant="body1">
              {subscription.month}: {subscription.status}
            </Typography>
          </li>
        ))}
      </ul>
      <Button variant="contained" color="primary" onClick={handlePayment}>
        Pay Now
      </Button>
      <Button variant="contained" color="secondary" onClick={() => console.log('Autopayment setup')}>
        Setup Autopayment
      </Button>
    </div>
  );
}
