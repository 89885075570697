import React, { useEffect, useState } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, 
  Container, Link, Box, Typography, Button, Dialog, Checkbox 
} from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import axios from 'axios';

const SelectFamilyDialog = ({ open, onClose, onSave ,parishUser}) => {
  const [families, setFamilies] = useState([]);
  const [selectedFamilies, setSelectedFamilies] = useState([]);

  useEffect(() => {
    // Fetch families from backend
    axios.get('/api/families')
      .then(response => {
        setFamilies(response.data);
      })
      .catch(error => {
        console.error("There was an error fetching the families!", error);
      });
  }, []);

  const handleSelectFamily = (family) => {
    const isSelected = selectedFamilies.some(f => f._id === String(family._id)); // Ensure comparison is made with string IDs
    if (isSelected) {
      setSelectedFamilies(selectedFamilies.filter(f => f._id !== String(family._id))); // Ensure family ID is a string
    } else {
      setSelectedFamilies([...selectedFamilies, { ...family, _id: String(family._id) }]); // Convert family ID to string when adding
    }
  };
  

  const handleSave = () => {
    const minimalFamilies = selectedFamilies.map(family => ({
      _id: family._id,
      familyCardNumber: family.familyCardNumber
    }));
    onSave(minimalFamilies);
    onClose();
  };
  
  const [anbiams, setAnbiams] = useState([]);

  useEffect(() => {
    const fetchAnbiams = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/anbiams');
        const data = await response.json();
        setAnbiams(data);
      } catch (error) {
        console.error('Error fetching anbiams:', error);
      }
    };

    fetchAnbiams();
  }, []);

  // Filter anbiams based on parishUser's parish name
  var filteredAnbiams = anbiams

  if(parishUser){
    
  filteredAnbiams = anbiams.filter(anbiam => anbiam.parishName === parishUser.name);
  
  }
  // Filter families based on anbiam
  var filteredFamilies = families
  

  if(!parishUser)
    {
      filteredFamilies = families.filter(family => 
        filteredAnbiams.some(anbiam => (anbiam._id === family.anbiamId) || family.anbiamId==="")
      );
    }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      <Container>
        <Box sx={{ my: 4 }}>
          <Typography variant="h4" gutterBottom>
            Select Families
          </Typography>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="family table">
              <TableHead>
                <TableRow>
                  <TableCell>Select</TableCell>
                  <TableCell>Family Card Number</TableCell>
                  <TableCell>Family Head Name</TableCell>
                  <TableCell>Family Photo</TableCell>
                  <TableCell>Phone Number</TableCell>
                  <TableCell>Google Map Link</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredFamilies.map((family) => (
                  <TableRow key={family._id}>
                    <TableCell>
                      <Checkbox
                        checked={selectedFamilies.some(f => f._id === family._id)}
                        onChange={() => handleSelectFamily(family)}
                      />
                    </TableCell>
                    <TableCell>
                      <RouterLink to={`/family/${family._id}`}>
                        {family.familyCardNumber}
                      </RouterLink>
                    </TableCell>
                    <TableCell>{family.familyHeadName}</TableCell>
                    <TableCell>
                      <img src={family.familyPhoto} alt={`${family.familyHeadName} Family`} style={{ width: '100px', height: '100px' }} />
                    </TableCell>
                    <TableCell>{family.phoneNumber}</TableCell>
                    <TableCell>
                      <Link href={family.googleMapLink} target="_blank" rel="noopener">
                        View on Google Maps
                      </Link>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <Button variant="contained" color="primary" onClick={handleSave} sx={{ mr: 2 }}>
              Select
            </Button>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Container>
    </Dialog>
  );
};

export default SelectFamilyDialog;
