import React, { useEffect, useState } from 'react';
import { 
  Container, Typography, Paper, Box, TextField, Button, Link, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Divider, IconButton, InputAdornment 
} from '@mui/material';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@mui/icons-material';

// Define your API base URL
const API_BASE_URL = process.env.REACT_APP_API_URL+'/api'; // Adjust the port if necessary

export default function ParishDetails() {
  const { parishId } = useParams(); // Get the parish ID from the URL params
  const [parish, setParish] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchParishDetails = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/parish/${parishId}`);
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        setParish(data);
      } catch (err) {
        console.error('Error fetching parish details:', err);
        setError('Failed to load parish details.');
      }
    };

    fetchParishDetails();
  }, [parishId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setParish({ ...parish, [name]: value });
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  if (!parish) {
    return <Typography>Loading...</Typography>;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Parish Details
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Parish Name"
            name="name"
            value={parish.name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Father's Name"
            name="fatherName"
            value={parish.fatherName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Phone Number"
            name="fatherPhone"
            value={parish.fatherPhone}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Vicarate ID"
            name="vicarateId"
            value={parish.vicarateId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Vicarate Name"
            name="vicarateName"
            value={parish.vicarateName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          {/* Username */}
          <TextField
            label="Username"
            name="userId"
            value={parish.userId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          {/* Password */}
          <TextField
            label="Password"
            name="password"
            type={showPassword ? "text" : "password"}
            value={parish.password || ''} // Ensure it doesn't break if `password` is missing
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={togglePasswordVisibility}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box sx={{ mt: 2 }}>
            <Link href={parish.googleMapLink} target="_blank" rel="noopener">
              Show in Map
            </Link>
            <Button
              variant="outlined"
              sx={{ ml: 2 }}
              onClick={() => {
                navigator.clipboard.writeText(parish.googleMapLink)
                  .then(() => {
                    alert('Google Map link copied to clipboard!');
                  })
                  .catch((err) => {
                    console.error('Failed to copy: ', err);
                  });
              }}
            >
              Copy Link
            </Button>
          </Box>
        </Box>

        <Divider sx={{ my: 4 }} />

        {/* Sub-Station List */}
        <Typography variant="h5" gutterBottom>
          Sub-Station List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="substation table">
            <TableHead>
              <TableRow>
                <TableCell>Sub-Station ID</TableCell>
                <TableCell>Sub-Station Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parish.subStations?.length > 0 ? (
                parish.subStations.map((subStation) => (
                  <TableRow key={subStation.id || subStation._id}>
                    <TableCell>{subStation.id}</TableCell>
                    <TableCell>{subStation.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>No Sub-Stations Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        <Divider sx={{ my: 4 }} />

        {/* Anbiam List */}
        <Typography variant="h5" gutterBottom>
          Anbiam List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="anbiam table">
            <TableHead>
              <TableRow>
                <TableCell>Anbiam ID</TableCell>
                <TableCell>Anbiam Name</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parish.anbiam?.length > 0 ? (
                parish.anbiam.map((anbiam) => (
                  <TableRow key={anbiam.id || anbiam._id}>
                    <TableCell>{anbiam.id}</TableCell>
                    <TableCell>{anbiam.name}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={2}>No Anbiams Found</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Container>
  );
}
