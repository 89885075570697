import React, { useState } from 'react';
import { Typography, List, ListItem, ListItemText, Button } from '@mui/material';
import jsPDF from 'jspdf';

const massSchedules = [
  { label: 'Sunday English Mass 15/6/2024 6:00AM', value: 'sunday_english_15_6_2024_6AM' },
  { label: 'Sunday Spanish Mass 15/6/2024 8:00AM', value: 'sunday_spanish_15_6_2024_8AM' },
];

const userIntentions = [
  { user: 'John Doe', mass: 'sunday_english_15_6_2024_6AM', intention: 'For health', amount: 50 },
  { user: 'Jane Doe', mass: 'sunday_english_15_6_2024_6AM', intention: 'In thanksgiving', amount: 75 },
];

export default function AdminMassIntention() {
  const [selectedMass, setSelectedMass] = useState('');

  const handleDownloadPdf = () => {
    const doc = new jsPDF();
    doc.text('User Intentions', 10, 10);
    userIntentions
      .filter((intention) => intention.mass === selectedMass)
      .forEach((intention, index) => {
        doc.text(`${index + 1}. ${intention.user} - ${intention.intention} - $${intention.amount}`, 10, 20 + index * 10);
      });
    doc.save('User_Intentions.pdf');
  };

  return (
    <div>
      <Typography variant="h4">Admin Mass Intention</Typography>
      <List>
        {massSchedules.map((mass) => (
          <ListItem button key={mass.value} onClick={() => setSelectedMass(mass.value)}>
            <ListItemText primary={mass.label} />
          </ListItem>
        ))}
      </List>
      <Typography variant="h6">Selected Mass: {selectedMass}</Typography>
      <List>
        {userIntentions
          .filter((intention) => intention.mass === selectedMass)
          .map((intention, index) => (
            <ListItem key={index}>
              <ListItemText primary={`${intention.user} - ${intention.intention} - $${intention.amount}`} />
            </ListItem>
          ))}
      </List>
      <Button variant="contained" color="primary" onClick={handleDownloadPdf}>
        Download as PDF
      </Button>
    </div>
  );
}
