import React, { useEffect, useState } from 'react';
import { Container, Typography, Paper, Box, TextField, Link } from '@mui/material';
import { useParams } from 'react-router-dom';
import axios from 'axios'; // Make sure to install axios for API requests

export default function SubstationDetails() {
  const { substationId } = useParams(); // Get the substation ID from the URL params
  const [substation, setSubstation] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSubstationDetails = async () => {
      try {
        const response = await axios.get(`/api/substations/${substationId}`);
        setSubstation(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching substation details:', error);
        setLoading(false);
      }
    };

    fetchSubstationDetails();
  }, [substationId]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setSubstation({ ...substation, [name]: value });
  };

  if (loading) {
    return <Typography>Loading...</Typography>;
  }

  if (!substation) {
    return <Typography>No substation found.</Typography>;
  }
console.log(substation);
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 3, marginTop: 4 }}>
        <Typography variant="h4" gutterBottom>
          Substation Details
        </Typography>

        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            label="Substation Name"
            name="substationName"
            value={substation.substationName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Parish ID"
            name="parishId"
            value={substation.parishId}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <TextField
            label="Parish Name"
            name="parishName"
            value={substation.parishName}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            margin="normal"
          />

          <Typography variant="h6" gutterBottom>
            Anbiam List
          </Typography>
          <ul>
            {substation.anbiamList.map((anbiam, index) => (
              <li key={index}>
                <Link href={`/anbiam/${anbiam.id}`} target="_blank">
                  {`ID: ${anbiam.id}, Name: ${anbiam.name}`}
                </Link>
              </li>
            ))}
          </ul>
        </Box>
      </Paper>
    </Container>
  );
}
