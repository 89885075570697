import React, { useState } from 'react';
import { Button, IconButton, TextField, Typography, Box } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import axios from 'axios';
import SelectSubstation from '../Selecter/SelectSubstation'; // Import Substation dialog
import SelectAnbiam from '../Selecter/SelectAnbiam'; // Import Anbiam dialog
import SelectVicarate from '../Selecter/SelectVicarate'; // Import Vicarate dialog

function CreateParish() {
  const [parishData, setParishData] = useState({
    name: '',
    fatherName: '',
    fatherPhone: '',
    userId: '',
    password: '',
    vicarateId: '',
    vicarateName: '',
    subStations: [{ id: '', name: '' }],
    anbiam: [], // Initially an empty array for anbiams
  });

  // Dialog state
  const [substationDialogOpen, setSubstationDialogOpen] = useState(false);
  const [anbiamDialogOpen, setAnbiamDialogOpen] = useState(false);
  const [vicarateDialogOpen, setVicarateDialogOpen] = useState(false);
  const [selectedSubstationIndex, setSelectedSubstationIndex] = useState(null);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setParishData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  // Substation functions
  const handleSubStationChange = (index, substation) => {
    const newSubStations = parishData.subStations.map((station, i) =>
      i === index ? { id: substation._id, name: substation.substationName } : station
    );
    setParishData((prevData) => ({ ...prevData, subStations: newSubStations }));
  };

  const handleAddSubStation = () => {
    setParishData((prevData) => ({
      ...prevData,
      subStations: [...prevData.subStations, { id: '', name: '' }],
    }));
  };

  const handleRemoveSubStation = (index) => {
    setParishData((prevData) => ({
      ...prevData,
      subStations: prevData.subStations.filter((_, i) => i !== index),
    }));
  };

  const handleOpenSubstationDialog = (index) => {
    setSelectedSubstationIndex(index);
    setSubstationDialogOpen(true);
  };

  // Anbiam functions
  const handleAddAnbiam = (anbiams) => {
    console.log("Received Anbiams from dialog:", anbiams);
  
    const formattedAnbiams = anbiams.map(anbiam => ({
      id: anbiam._id,  // Ensure correct id is being used
      name: anbiam.anbiamName // Ensure correct name is being used
    }));
  
    setParishData((prevData) => ({
      ...prevData,
      anbiam: [...prevData.anbiam, ...formattedAnbiams], // Append new Anbiam data
    }));
  };
  
  

  const handleRemoveAnbiam = (index) => {
    setParishData((prevData) => ({
      ...prevData,
      anbiam: prevData.anbiam.filter((_, i) => i !== index),
    }));
  };

  const handleOpenAnbiamDialog = () => {
    setAnbiamDialogOpen(true);
  };

  // Vicarate functions
  const handleSelectVicarate = (vicarate) => {
    setParishData((prevData) => ({
      ...prevData,
      vicarateId: vicarate._id,
      vicarateName: vicarate.name,
    }));
    setVicarateDialogOpen(false); // Close the dialog after selection
  };

  const handleOpenVicarateDialog = () => {
    setVicarateDialogOpen(true);
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post('http://127.0.0.1:5000/parish', parishData);
      console.log('Parish created successfully:', response.data);
    } catch (error) {
      console.error('There was an error creating the parish!', error);
    }
  };

  return (
    <Box sx={{ p: 4 }}>
      <Typography variant="h4" gutterBottom>Create Parish</Typography>

      {/* Other Parish fields */}
      <TextField
        label="Name of Parish"
        name="name"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={parishData.name}
        onChange={handleChange}
      />
      {/* ... other fields like Father details */}
      <TextField
        label="Name of Father"
        name="fatherName"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={parishData.fatherName}
        onChange={handleChange}
      />
      <TextField
        label="Phone No of Father"
        name="fatherPhone"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={parishData.fatherPhone}
        onChange={handleChange}
      />

      <TextField
        label="Parish User ID"
        name="userId"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={parishData.userId}
        onChange={handleChange}
      />
      <TextField
        label="Password"
        name="password"
        type="password"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={parishData.password}
        onChange={handleChange}
      />

      {/* Vicarate Section */}
      <Typography variant="h6">Select Vicarate</Typography>
      <Button
        variant="outlined"
        onClick={handleOpenVicarateDialog}
        sx={{ mb: 2 }}
      >
        {parishData.vicarateName ? parishData.vicarateName : 'Select Vicarate'}
      </Button>

      {/* Substations Section */}
      <Typography variant="h6">List of Sub-Stations</Typography>
      {parishData.subStations.map((station, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <Button variant="outlined" onClick={() => handleOpenSubstationDialog(index)}>
            {station.name ? station.name : 'Select Sub-Station'}
          </Button>
          <IconButton onClick={() => handleRemoveSubStation(index)} color="error">
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        startIcon={<AddCircleOutline />}
        onClick={handleAddSubStation}
        sx={{ mb: 2 }}
      >
        Add Sub-Station
      </Button>

      {/* Anbiam Section */}
      <Typography variant="h6">List of Anbiam</Typography>
      {parishData.anbiam.length > 0 ? (
        parishData.anbiam.map((group, index) => (
          <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
            <Typography variant="body1">{group.name}</Typography> {/* Change to group.name */}
            <IconButton onClick={() => handleRemoveAnbiam(index)} color="error">
              <RemoveCircleOutline />
            </IconButton>
          </Box>
        ))
      ) : (
        <Typography>No Anbiam selected</Typography>
      )}
      <Button
        variant="outlined"
        startIcon={<AddCircleOutline />}
        onClick={handleOpenAnbiamDialog}
        sx={{ mb: 2 }}
      >
        Add Anbiam
      </Button>


      {/* Submit Button */}
      <Button variant="contained" color="primary" onClick={handleSubmit}>
        Submit Parish Data
      </Button>

      {/* Substation Selection Dialog */}
      <SelectSubstation
        open={substationDialogOpen}
        onClose={() => setSubstationDialogOpen(false)}
        onSelect={(substation) => handleSubStationChange(selectedSubstationIndex, substation)}
      />

      {/* Anbiam Selection Dialog */}
      <SelectAnbiam
        open={anbiamDialogOpen}
        onClose={() => setAnbiamDialogOpen(false)}
        onSave={handleAddAnbiam} // Corrected onSave function
      />

      {/* Vicarate Selection Dialog */}
      <SelectVicarate
        open={vicarateDialogOpen}
        onClose={() => setVicarateDialogOpen(false)}
        onSave={handleSelectVicarate}
      />
    </Box>
  );
}

export default CreateParish;
