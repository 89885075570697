import React, { useState } from 'react';
import { TextField, Button, Typography, Box, IconButton } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import axios from 'axios';
import SelectParish from '../Selecter/SelectParish'; // Import Parish Selector Dialog
import SelectSubstation from '../Selecter/SelectSubstation'; // Import Substation Selector Dialog
import SelectFamilyDialog from '../Selecter/SelectFamilyDialog'; // Import Family Selector Dialog

function CreateAnbiam(parishUser) {
  const [anbiamName, setAnbiamName] = useState('');
  const [representativeName, setRepresentativeName] = useState('');
  const [phoneNo, setPhoneNo] = useState('');

  // Parish state
  const [parishId, setParishId] = useState('');
  const [parishName, setParishName] = useState('');
  const [isParishDialogOpen, setParishDialogOpen] = useState(false);

  // Substation state
  const [substationId, setSubstationId] = useState('');
  const [substationName, setSubstationName] = useState('');
  const [isSubstationDialogOpen, setSubstationDialogOpen] = useState(false);

  // Families state
  const [families, setFamilies] = useState([]);
  const [isFamilyDialogOpen, setFamilyDialogOpen] = useState(false);

  // Open/Close Dialog Handlers
  const handleParishDialogOpen = () => setParishDialogOpen(true);
  const handleParishDialogClose = () => setParishDialogOpen(false);

  const handleSubstationDialogOpen = () => setSubstationDialogOpen(true);
  const handleSubstationDialogClose = () => setSubstationDialogOpen(false);

  const handleFamilyDialogOpen = () => setFamilyDialogOpen(true);
  const handleFamilyDialogClose = () => setFamilyDialogOpen(false);

  // Handlers for selecting Parish, Substation, and Families
  const handleParishSelect = (parish) => {
    setParishId(parish._id);
    setParishName(parish.name);
  };

  const handleSubstationSelect = (substation) => {
    setSubstationId(substation._id);
    setSubstationName(substation.substationName);
  };

  const handleFamiliesSave = (selectedFamilies) => {
    setFamilies(selectedFamilies.map(family => ({
      id: String(family._id),
      cardNumber: family.familyCardNumber,
    })));
  };

// Form submission
const handleSubmit = async (event) => {
  event.preventDefault();

  // Prepare families data in the correct format
  const formattedFamilies = families.map(family => ({
    id: family.id, // Make sure this corresponds to 'familyId' in backend
    familyCardNumber: family.cardNumber // Ensure this matches 'familyCardNumber' in backend
  }));

  // Prepare the full data object
  const data = {
    anbiamName,
    representativeName,
    phoneNo,
    parishId,
    parishName,
    substationId,
    substationName,
    families: formattedFamilies, // Send properly formatted families
  };

  try {
    const response = await axios.post(process.env.REACT_APP_API_URL+'/api/anbiam', data);
    console.log(data);
  } catch (error) {
    console.error('Error submitting the form:', error);
  }
};


  return (
    <Box sx={{ p: 4 }} component="form" onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>Create Anbiam</Typography>

      <TextField
        label="Name of Anbiam"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={anbiamName}
        onChange={(e) => setAnbiamName(e.target.value)}
      />

      <TextField
        label="Name of Anbiam Representative"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={representativeName}
        onChange={(e) => setRepresentativeName(e.target.value)}
      />

      <TextField
        label="Phone No of Anbiam Representative"
        variant="outlined"
        fullWidth
        sx={{ mb: 2 }}
        value={phoneNo}
        onChange={(e) => setPhoneNo(e.target.value)}
      />

      <Typography variant="h6">Parish</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography>{parishName ? `${parishName} (ID: ${parishId})` : 'No Parish Selected'}</Typography>
        <Button variant="outlined" onClick={handleParishDialogOpen} sx={{ ml: 2 }}>
          Select Parish
        </Button>
      </Box>

      <Typography variant="h6">Substation</Typography>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography>{substationName ? `${substationName} (ID: ${substationId})` : 'No Substation Selected'}</Typography>
        <Button variant="outlined" onClick={handleSubstationDialogOpen} sx={{ ml: 2 }}>
          Select Substation
        </Button>
      </Box>

      <Typography variant="h6">List of Families</Typography>
      <Box sx={{ mb: 2 }}>
        {families.length > 0 ? (
          <ul>
            {families.map((family, index) => (
              <li key={index}>
                Family Card Number: {family.cardNumber}, ID: {family.id}
              </li>
            ))}
          </ul>
        ) : (
          <Typography>No Families Selected</Typography>
        )}
        <Button variant="outlined" onClick={handleFamilyDialogOpen}>
          Select Families
        </Button>
      </Box>

      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>

      {/* Dialog Components */}
      <SelectParish
        open={isParishDialogOpen}
        onClose={handleParishDialogClose}
        onSelect={handleParishSelect}
      />

      <SelectSubstation
        open={isSubstationDialogOpen}
        onClose={handleSubstationDialogClose}
        onSelect={handleSubstationSelect}
        parishUser={parishUser}
      />

      <SelectFamilyDialog
        open={isFamilyDialogOpen}
        onClose={handleFamilyDialogClose}
        onSave={handleFamiliesSave}
        parishUser={parishUser}
      />
    </Box>
  );
}

export default CreateAnbiam;
