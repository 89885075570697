import React from 'react';
import { Box, Card, CardContent, Typography } from '@mui/material';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Schedule = () => {
  const scheduleData = [
    { id: 1, title: 'Sunday Mass', date: new Date('2024-06-16T10:00:00') },
    { id: 2, title: 'Wednesday Prayer', date: new Date('2024-06-19T19:00:00') },
  ];

  const getTileContent = ({ date, view }) => {
    if (view === 'month') {
      return scheduleData
        .filter(event => event.date.toDateString() === date.toDateString())
        .map(event => (
          <Box key={event.id} sx={{ p: 0.5, backgroundColor: '#1976d2', color: '#fff', borderRadius: '4px', m: 0.5 }}>
            <Typography variant="body2" sx={{ fontWeight: 'bold' }}>{event.title}</Typography>
            <Typography variant="body2">{event.date.toLocaleTimeString()}</Typography>
          </Box>
        ));
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Schedule</Typography>
      <Calendar tileContent={getTileContent} />
    </Box>
  );
};

export default Schedule;
