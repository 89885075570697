import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Typography, Table, TableBody, TableCell, TableHead, TableRow, Paper, TableContainer } from '@mui/material';

export default function OurDevotees() {
  const [submissions, setSubmissions] = useState([]);

  useEffect(() => {
    const fetchSubmissions = async () => {
      const mockData = [
        {
          id: 1,
          familyCardNumber: 'FCN123456',
          name: 'John Doe',
          phoneNumber: '123-456-7890',
          birthPlace: 'City A',
          birthDate: '1990-01-01',
        },
        {
          id: 2,
          familyCardNumber: 'FCN789012',
          name: 'Jane Smith',
          phoneNumber: '987-654-3210',
          birthPlace: 'City B',
          birthDate: '1985-05-15',
        },
      ];
      setSubmissions(mockData);
    };

    fetchSubmissions();
  }, []);

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Our Devotees
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Family Card Number</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Birth Place</TableCell>
              <TableCell>Birth Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {submissions.map((submission) => (
              <TableRow key={submission.id} component={Link} to={`/devotee/${submission.id}`}>
                <TableCell>{submission.familyCardNumber}</TableCell>
                <TableCell>{submission.name}</TableCell>
                <TableCell>{submission.phoneNumber}</TableCell>
                <TableCell>{submission.birthPlace}</TableCell>
                <TableCell>{submission.birthDate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}
