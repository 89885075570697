import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Link, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const ParishList = () => {
  const [parishes, setParishes] = useState([]);

  useEffect(() => {
    const fetchParishes = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/parishes');
        const data = await response.json();
        setParishes(data);
      } catch (error) {
        console.error('Error fetching parishes:', error);
      }
    };

    fetchParishes();
  }, []);

  return (
    <Container>
      <Box sx={{ my: 4 }}>
        <Typography variant="h4" gutterBottom>
          Parish List
        </Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="parish table">
            <TableHead>
              <TableRow>
                <TableCell>Parish Name</TableCell>
                <TableCell>Father's Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Address</TableCell>
                <TableCell>Google Map Link</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {parishes.map((parish) => (
                <TableRow key={parish._id}>
                  <TableCell>
                    {/* Route based on _id */}
                    <RouterLink to={`/parish/${parish._id}`}>
                      {parish.name}
                    </RouterLink>
                  </TableCell>
                  <TableCell>{parish.fatherName}</TableCell>
                  <TableCell>{parish.fatherPhone}</TableCell>
                  <TableCell>
                    {parish.address?.line1 || 'N/A'}
                    <br />
                    {parish.address?.line2 || ''}
                    <br />
                    {parish.address?.line3 || ''}
                  </TableCell>
                  <TableCell>
                    <Link href={parish.googleMapLink} target="_blank" rel="noopener">
                      View on Google Maps
                    </Link>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Container>
  );
};

export default ParishList;
