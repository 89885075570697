import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Link, Box, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

const families = [
  {
    familyCardNumber: 'FCN123456',
    familyHeadName: 'John Smith',
    familyPhoto: 'Path/to/FamilyPhoto1.jpg',
    phoneNumber: '123-456-7890',
    googleMapLink: 'https://goo.gl/maps/example',
  },
  {
    familyCardNumber: 'FCN654321',
    familyHeadName: 'Jane Doe',
    familyPhoto: 'Path/to/FamilyPhoto2.jpg',
    phoneNumber: '987-654-3210',
    googleMapLink: 'https://goo.gl/maps/example',
  },
  // Add more families as needed
];

const FamilyList = () => (
  <Container>
    <Box sx={{ my: 4 }}>
      <Typography variant="h4" gutterBottom>
        Family Card List
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="family table">
          <TableHead>
            <TableRow>
              <TableCell>Family Card Number</TableCell>
              <TableCell>Family Head Name</TableCell>
              <TableCell>Family Photo</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Google Map Link</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {families.map((family, index) => (
              <TableRow key={index}>
                <TableCell>
                  <RouterLink to={`/family/${family.familyCardNumber}`}>
                    {family.familyCardNumber}
                  </RouterLink>
                </TableCell>
                <TableCell>{family.familyHeadName}</TableCell>
                <TableCell>
                  <img src={family.familyPhoto} alt={`${family.familyHeadName} Family`} style={{ width: '100px', height: 'auto' }} />
                </TableCell>
                <TableCell>{family.phoneNumber}</TableCell>
                <TableCell>
                  <Link href={family.googleMapLink} target="_blank" rel="noopener">
                    View on Google Maps
                  </Link>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  </Container>
);

export default FamilyList;
