import React, { useState, useEffect } from 'react';
import { 
  Table, 
  TableBody, 
  TableCell, 
  TableHead, 
  TableRow, 
  Paper, 
  TableContainer, 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Checkbox, 
  TextField 
} from '@mui/material';

export default function SelectDevotee({ onSelect }) {
  const [submissions, setSubmissions] = useState([]);
  const [selected, setSelected] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');

  useEffect(() => {
    const fetchSubmissions = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_API_URL+'/api/devotees');
        const data = await response.json();
        setSubmissions(data);
      } catch (error) {
        console.error('Error fetching devotees:', error);
      }
    };

    fetchSubmissions();
  }, []);

  const handleSelect = (devotee) => {
    setSelected((prevSelected) => 
      prevSelected.some((item) => item._id === devotee._id)
        ? prevSelected.filter((item) => item._id !== devotee._id)
        : [...prevSelected, devotee]
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value.toLowerCase());
  };

  const handleSubmit = () => {
    onSelect(selected);
  };

  const filteredSubmissions = submissions.filter((submission) =>
    submission.name?.toLowerCase().includes(searchQuery)
  );

  // Filter submissions based on empty, null, or undefined anbiamId
const filteredSubmissionsWithEmptyAnbiamId = filteredSubmissions.filter(submission => 
  submission.familyId === ""
);

  return (
    <Dialog open={true} onClose={handleSubmit} maxWidth="lg" fullWidth>
      <DialogTitle>Our Devotees</DialogTitle>
      <DialogContent>
        <TextField
          label="Search Devotees"
          variant="outlined"
          fullWidth
          margin="normal"
          value={searchQuery}
          onChange={handleSearch}
        />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Select</TableCell>
                <TableCell>Family Card Number</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Phone Number</TableCell>
                <TableCell>Birth Place</TableCell>
                <TableCell>Birth Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredSubmissionsWithEmptyAnbiamId.map((submission) => (
                <TableRow key={submission._id}>
                  <TableCell>
                    <Checkbox
                      checked={selected.some((item) => item._id === submission._id)}
                      onChange={() => handleSelect(submission)}
                    />
                  </TableCell>
                  <TableCell>{submission.familyCardNumber || 'N/A'}</TableCell>
                  <TableCell>{submission.name || 'N/A'}</TableCell>
                  <TableCell>{submission.phoneNumber || 'N/A'}</TableCell>
                  <TableCell>{submission.birthPlace || 'N/A'}</TableCell>
                  <TableCell>{submission.birthDate || 'N/A'}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} color="primary">
          Select
        </Button>
      </DialogActions>
    </Dialog>
  );
}
