import React, { useState } from 'react';
import axios from 'axios';
import { TextField, Button, Box, Typography, IconButton, Dialog } from '@mui/material';
import { AddCircleOutline, RemoveCircleOutline } from '@mui/icons-material';
import DevoteeList from '../Selecter/SelectDevotee';
import SelectAnbiam from '../Selecter/SelectAnbiam';

const FamilyForm = ({parishUser}) => {
  const [formData, setFormData] = useState({
    familyCardNumber: '',
    familyHeadName: '',
    familyPhoto: null,
    phoneNumber: '',
    email: '',
    line1: '',
    line2: '',
    line3: '',
    googleMapLink: '',
    devoteeIds: [{ id: '', name: '' }],
    anbiamId: '',
    anbiamName: '',
  });

  const [openDevoteeDialog, setOpenDevoteeDialog] = useState(false);
  const [openAnbiamDialog, setOpenAnbiamDialog] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      familyPhoto: e.target.files[0],
    });
  };

  const handleDevoteeChange = (index, e) => {
    const newDevotees = [...formData.devoteeIds];
    newDevotees[index][e.target.name] = e.target.value;
    setFormData({ ...formData, devoteeIds: newDevotees });
  };

 

  const removeDevotee = (index) => {
    const newDevotees = formData.devoteeIds.filter((_, i) => i !== index);
    setFormData({ ...formData, devoteeIds: newDevotees });
  };

  const handleOpenDevoteeDialog = () => {
    setOpenDevoteeDialog(true);
  };

  const handleCloseDevoteeDialog = () => {
    setOpenDevoteeDialog(false);
  };

  // Handle selected devotees from DevoteeList
  const handleDevoteeSelect = (selectedDevotees) => {
    const updatedDevotees = selectedDevotees.map((devotee) => ({
      id: devotee._id,
      name: devotee.name,
    }));
    setFormData({ ...formData, devoteeIds: updatedDevotees });
    handleCloseDevoteeDialog();
  };

  // Handle Anbiam selection
  const handleOpenAnbiamDialog = () => {
    setOpenAnbiamDialog(true);
  };

  const handleCloseAnbiamDialog = () => {
    setOpenAnbiamDialog(false);
  };

  const handleAnbiamSelect = (selectedAnbiam) => {
    if (selectedAnbiam.length > 0) {
      const anbiam = selectedAnbiam[0]; // Assume single selection for now
      setFormData({
        ...formData,
        anbiamId: anbiam._id,
        anbiamName: anbiam.anbiamName,
      });
    }
    handleCloseAnbiamDialog();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (const key in formData) {
      if (key === 'devoteeIds') {
        data.append(key, JSON.stringify(formData[key]));
      } else {
        data.append(key, formData[key]);
      }
    }
    axios.post(process.env.REACT_APP_API_URL+'/families', data)
      .then((response) => alert('Family record created!'))
      .catch((error) => console.error('There was an error creating the record!', error));
  };

  return (
    <Box component="form" onSubmit={handleSubmit} sx={{ p: 2 }}>
      <Typography variant="h6">Family Information</Typography>
      <TextField
        label="Family Card Number"
        name="familyCardNumber"
        value={formData.familyCardNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Family Head Name"
        name="familyHeadName"
        value={formData.familyHeadName}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <Button variant="contained" component="label" sx={{ my: 2 }}>
        Upload Family Photo
        <input
          type="file"
          name="familyPhoto"
          onChange={handleFileChange}
          hidden
        />
      </Button>
      <TextField
        label="Phone Number"
        name="phoneNumber"
        value={formData.phoneNumber}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Email"
        name="email"
        value={formData.email}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address Line 1"
        name="line1"
        value={formData.line1}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address Line 2"
        name="line2"
        value={formData.line2}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Address Line 3"
        name="line3"
        value={formData.line3}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Google Map Link"
        name="googleMapLink"
        value={formData.googleMapLink}
        onChange={handleChange}
        fullWidth
        margin="normal"
      />

      {/* Devotee List */}
      <Typography variant="subtitle1" sx={{ mt: 2 }}>Devotee List</Typography>
      {formData.devoteeIds.map((devotee, index) => (
        <Box key={index} sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <TextField
            label="Devotee Name"
            name="name"
            value={devotee.name}
            onChange={(e) => handleDevoteeChange(index, e)}
            fullWidth
            sx={{ ml: 2 }}
          />
          <IconButton
            onClick={() => removeDevotee(index)}
            color="error"
            sx={{ ml: 1 }}
            disabled={formData.devoteeIds.length <= 1}
          >
            <RemoveCircleOutline />
          </IconButton>
        </Box>
      ))}
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenDevoteeDialog}
        startIcon={<AddCircleOutline />}
        sx={{ mb: 2 }}
      >
        Add Devotee
      </Button>

      {/* Button to open SelectAnbiam dialog */}
      <Button
        variant="outlined"
        color="primary"
        onClick={handleOpenAnbiamDialog}
        sx={{ mb: 2 }}
      >
        Select Anbiam
      </Button>

      {formData.anbiamName && (
        <Typography variant="body1" sx={{ mb: 2 }}>
          Selected Anbiam: {formData.anbiamName}
        </Typography>
      )}

      <Button type="submit" variant="contained" color="primary" fullWidth>
        Submit
      </Button>

      {/* Dialog for Devotee List */}
      <Dialog open={openDevoteeDialog} onClose={handleCloseDevoteeDialog} fullWidth maxWidth="lg">
        <DevoteeList onSelect={handleDevoteeSelect} parishUser={parishUser}/> {/* Pass necessary props */}
      </Dialog>

      {/* Dialog for SelectAnbiam */}
      <Dialog open={openAnbiamDialog} onClose={handleCloseAnbiamDialog} fullWidth maxWidth="md">
        <SelectAnbiam open={openAnbiamDialog} onClose={handleCloseAnbiamDialog} onSave={handleAnbiamSelect} parishUser={parishUser}/>
      </Dialog>
    </Box>
  );
};

export default FamilyForm;
