import React, { useState } from 'react';
import { Box, Button, Typography, TextField, MenuItem } from '@mui/material';

const Donations = () => {
  const [donationType, setDonationType] = useState('church');

  const handleDonate = () => {
    // Integrate payment gateway logic
    console.log(`Donation button clicked for ${donationType}`);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography variant="h5" sx={{ m: 2 }}>Support Our Church</Typography>
      <TextField
        select
        label="Donation Type"
        value={donationType}
        onChange={(e) => setDonationType(e.target.value)}
        fullWidth
        margin="normal"
        sx={{ width: 300 }}
      >
        <MenuItem value="church">For Church</MenuItem>
        <MenuItem value="special">For Special Needs</MenuItem>
      </TextField>
      <Button variant="contained" color="primary" onClick={handleDonate}>
        Donate
      </Button>
    </Box>
  );
};

export default Donations;
